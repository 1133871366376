import axios from "axios";
import store from '@/store'
import Vue from 'vue'
import router from '@/router'
// 初始化token
let token = localStorage.getItem('token')
// store.commit("setToken", token ? token: '');

const service = axios.create({
    method: "post",
    timeout: 60000,
    headers: { Authorization: token }
    // headers: { Authorization: store.state.token }
    // headers: { Authorization: 'E66D50F35FD055DE72050192A04119792C6E616F77797FB8' }
    // 请求超时，8s
    // timeout: 10000
});
/**
 * 请求拦截器
 */
service.interceptors.request.use(
    config => config,
    error => error
);
/**
 * 响应拦截器
 * 拦截相应请求并且直接返回data字段
 */
service.interceptors.response.use(
    res => res.data,
    error => {
        if (error.response && error.response.status == 401) {
            //说明授权已过期，需要清除localStorage信息，以便/personalCenter进行判断
            localStorage.setItem("token", '')
            localStorage.setItem("whid", '')
            localStorage.setItem("confirm", 1)
            localStorage.setItem("phone", '')
            // 进入登录页面
            if(router.history.current.fullPath=='/personalCenter'){
                location.reload()
            }else{
                router.push('/personalCenter')
            }
        }
        if (error.message.includes("timeout")){
            // alert("请求超时，请稍候再试")
        }
        return error;

        
    }
);
let serviceGet = axios.create({
    method: "get",
    headers: { Authorization: token },
    // 请求超时，60s
    timeout: 60000
});
serviceGet.interceptors.request.use(
    config => config,
    error => error
);

serviceGet.interceptors.response.use(
    res => res.data,
    error => {
        if (error.response && error.response.status == 401) {
            //说明授权已过期，需要清除localStorage信息，以便/personalCenter进行判断
            localStorage.setItem("token", '')
            localStorage.setItem("whid", '')
            localStorage.setItem("confirm", 1)
            localStorage.setItem("phone", '')
            // 进入登录页面
            if(router.history.current.fullPath=='/personalCenter'){
                location.reload()
            }else{
                router.push('/personalCenter')
            }
        }
        return error;
    }
);


//登录后返回首页个人成绩报告
export const Login = (data) => {
    return service({
        url: "/api/user/Login",
        data:data,
    });
};

//首页-个人成绩报告
export const Performance = (data) => {
    return service({
        url: "/api/user/Performance",
        data:data,
    });
};

//大赛排行-月份下拉
export const GetMonthList = (data) => {
    return service({
        url: "/api/Rank/GetMonthList",
        data:data,
    });
};

//大赛排行-月综合得分
export const GetRankList = (data) => {
    return service({
        url: "/api/Rank/GetRankList",
        data:data,
    });
};

//大赛排行-比赛日盈利额
export const GetYLERankList = (data) => {
    return service({
        url: "/api/Rank/GetYLERankList",
        data:data,
    });
};

//大赛排行-比赛日最大回撤比
export const GetHCBRankList = (data) => {
    return service({
        url: "/api/Rank/GetHCBRankList",
        data: data,
    });
};

//大赛排行-比赛日盈亏比
export const GetYKBRankList = (data) => {
    return service({
        url: "/api/Rank/GetYKBRankList",
        data:data,
    });
};

//大赛排行-比赛日胜率
export const GetSLRankList = (data) => {
    return service({
        url: "/api/Rank/GetSLRankList",
        data:data,
    });
};

//模拟软件-软件相关
export const GetAppUpdateList = (data) => {
    return service({
        url: "/api/Download/GetAppUpdateList",
        data:data,
    });
};

//交易规则-获取表格信息
export const GetGoodsList_NP = (data) => {
    return service({
        url: "/api/SXF/GetGoodsList_NP",
        data:data,
    });
};

//模拟豆-获取表格信息
export const GetFeeCost = (data) => {
    return service({
        url: "/api/FeeCost/GetFeeCost",
        data:data,
    });
};

//个人中心-个人战绩
export const DetailInfo = (data) => {
    return service({
        url: "/api/user/DetailInfo",
        data:data,
    });
};

//个人中心-我的排名
export const MyRankList = (data) => {
    return service({
        url: "/api/user/MyRankList",
        data:data,
    });
};

//个人中心-模拟豆余额
export const FundInfo = (data) => {
    return service({
        url: "/api/user/FundInfo",
        data:data,
    });
};

//个人中心-修改密码
export const UpdateNickName = (data) => {
    return service({
        url: "/api/user/UpdateNickName",
        data: data,
    });
};

//个人中心-取文华豆价钱
export const GameCoinApi = (data) => {
    return service({
        url: "/api/ChargeApi/GameCoinApi",
        data: data,
    });
};

//个人中心-取订单号
export const GetOrderid = (data) => {
    return service({
        url: "/Charge/GetOrderid",
        data: data,
    });
};

//个人中心-微信支付宝支付
export const H5PayRequest = (data) => {
    return service({
        url: "/Charge/H5PayRequest",
        data: data,
    });
};

//个人中心-银联支付
export const H5ChinaPay = (data) => {
    return service({
        url: "/Charge/H5ChinaPay",
        data: data,
    });
};

// 个人中心-生成二维码支付（微信支付宝）
export const MicroPayQRCode = (data) => {
    return service({
        url: "/Charge/MicroPayQRCode",
        data: data,
    });
};

// 个人中心-实名认证信息
export const WalletApi = (data) => {
    return service({
        url: "/api/Confirmation/WalletApi",
        data: data,
    });
};

// 个人中心-模拟豆历史账单
export const GetFundDetail = (data) => {
    return service({
        url: "/api/TradeFee/GetFundDetail",
        data: data,
    });
};

// 个人中心-模拟豆历史账单
export const DayDetailJson = (data) => {
    return service({
        url: "/api/TradeFee/DayDetailJson",
        data: data,
    });
};

// 个人中心-发送验证码
export const SendCode = (data) => {
    return service({
        url: "/api/Captcha/SendCode",
        data: data,
    });
};

// 个人中心-提交实名认证信息
export const AddUser = (data) => {
    return service({
        url: "/api/Confirmation/AddUser",
        data: data,
    });
};

// 个人中心-提交实名认证信息
export const GetIsSuccess = (data) => {
    return service({
        url: "/Charge/GetIsSuccess",
        data: data,
    });
};

// 个人中心-退赛
export const Quit = (data) => {
    return service({
        url: "/api/user/Quit",
        data: data,
    });
};

// 个人中心-退赛用户信息
export const GetWallet = (data) => {
    return service({
        url: "/api/Confirmation/GetWallet",
        data: data,
    });
};

// 个人中心-修改昵称
export const NicknameGet = (data) => {
    return service({
        url: "/api/user/NicknameGet",
        data: data,
    });
};

// 个人中心-回显合同内容
export const ContractGameCoin = (data) => {
    return service({
        url: "/api/ChargeApi/ContractGameCoin",
        data: data,
    });
};

// 个人中心-是否可以绑定手机号
export const GetSimPhoneBindCheck = (data) => {
    // Zhlb（string型）账号 类别   --0:模拟 1:外盘 2:股票 3:ETF
    return service({
        url: "/api/user/GetSimPhoneBindCheck",
        data: data,
    });
};

// 个人中心-模拟账号绑定手机号发送验证码
export const SimulationSendCode = (data) => {
    return service({
        url: "/api/user/SimulationSendCode",
        data: data,
    });
};

// 个人中心-模拟账号验证验证码
export const SimulationCheckCode = (data) => {
    return service({
        url: "/api/user/SimulationCheckCode",
        data: data,
    });
};

// 个人中心-模拟账号确认绑定接口
export const SimulationMnBindingPhone = (data) => {
    return service({
        url: "/api/user/SimulationMnBindingPhone",
        data: data,
    });
};
// 个人中心-模拟账号确认绑定接口
export const GetDayRankList = (data) => {
    return service({
        url: "/api/Rank/GetDayRankList",
        data: data,
    });
};

//首页大赛公告滚动条
export const ScrollListGet = (data)=>{
    return serviceGet({
        url:'/api/NewsApi/ScrollListGet',
        data: data,
    })
}

// 大赛获奖名单
export const NewsListGet = (data) => {
    return service({
        url: '/api/NewsApi/NewsListGet',
        data: data,
    })
}

// 重新参赛提示接口
export const ReJoin = (data) => {
    return service({
        url: '/api/User/ReJoin',
        data: data,
    })
}
// 文华豆购买记录接口
export const GetSimBillData = (data) => {
    return service({
        url: '/api/TradeFee/GetSimBillData',
        data: data,
    })
}

// 文华豆购买记录接口
export const GetBillData = (data) => {
    return service({
        url: '/api/TradeFee/GetBillData ',
        data: data,
    })
}



// 首页获奖名单 比赛日
export const GetDayPrizeList = (data) => {
    return service({
        url: '/api/Rank/GetDayPrizeList',
        data: data,
    })
}


// 首页获奖名单 月冠军
export const GetMonthPrizeList = (data) => {
    return service({
        url: '/api/Rank/GetMonthPrizeList',
        data: data,
    })
}


// 个人中心 添加修改中奖人信息
export const PrizeUserUpdate = (data) => {
    return service({
        url: '/api/user/PrizeUserUpdate',
        data: data,
    })
}









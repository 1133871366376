<template>
    <div class="tab">
        <div
            v-for="(item,index) in tabList" :key="index"
            class="item"
            :class="{active:activeIndex==index}"
            @click="tabClick(item,index)"
        >{{item.name}}</div>
    </div>
</template>
<script>
export default {
    props:{
        tabList:Array
    },
    data(){
        return{
            activeIndex:0
        }
    },
    created(){
        this.$emit('tabClick',this.tabList[0]) //初始化
    },
    methods:{
        tabClick(item,index){
            let vm = this;
            vm.activeIndex = index;
            vm.$emit('tabClick',item) //传回被选中的标签实例
        }
    }
}
</script>
<style scoped>
.tab {
    display: flex;
}
.tab .item {
    font-size: 16px;
    color: #6c727a;
    width: 98px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #f0f2f5;
    cursor: pointer;
    user-select: none;
}
.tab .item.active,
.tab .item:active {
    color: #fff;
    background: #f5be7f;
}
</style>

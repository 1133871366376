<template>
<div>
    <div ref="chart" class="echart" v-loading="loading"></div>
    <div class="a1 about" @mouseenter="showabout=!showabout" @mouseleave="showabout=!showabout">说明</div>
    <div class="a2 about" v-if="showabout">
        <div class="flex"><div class="name">盈利能力</div><div>：</div><div>根据所有参赛账户的净值收益率比较，净值收益率越大，该项得分越高；</div></div>
        <div class="flex"><div class="name">风险控制</div><div>：</div><div>根据收益波动率波动大小，判断收益控制能力；</div></div>
        <div class="flex"><div class="name">市场感知</div><div>：</div><div>第二天的收益率大于前一天的收益率，次数越多，盘面感知能力越强；</div></div>
        <div class="flex"><div class="name">胜率</div><div>：</div><div>在总平仓操作笔数中盈利次数的比例；</div></div>
        <div class="flex"><div class="name">稳定性</div><div>：</div><div>反映账户的收益的稳定性，权益回撤越小，回撤次数越少，该项得分越高。</div></div>
    </div>
</div>
</template>
<script>
import * as echarts from 'echarts';
export default {
    name: 'Echart',
    data(){
        return {
            chart:null,
            option:{
                color: ['#4787EF'],
                title: {
                    text: '账户评级',
                    textStyle: {
                        color: '#6c727a',
                        opacity: 0.6,
                        fontSize: 12,
                        fontWeight: 'normal'
                    }
                },
                grid: {
                    top: 100
                },
                radar: {
                    radius: '60%',
                    indicator: [
                        { name: '盈利能力',max:130},
                        { name: '风险控制',max:130},
                        { name: '市场感知',max:130},
                        { name: '胜率',max:130},
                        { name: '稳定性',max:130}
                    ],
                    axisName:{
                        color: '#6c727a',
                        formatter: function (value, indicator) {
                            return value + '--';
                        }
                    },
                    nameGap: 7,
                    splitArea:{
                        areaStyle: {
                            color : ['#FFFFFF','#EDEDED']
                        }
                    },
                    splitLine:{
                        lineStyle: {
                            color: ['#BABABA']
                        }
                    }
                },
                series: [{
                    name: '账户评级',
                    type: 'radar',
                    data: [{
                        value: []
                    }]
                }],
                lable:{}
            },
            loading: false,
            showabout:false
        }
    },
    props:{
        userdata: Object
    },
    watch:{
        'userdata':function(){
            this.init()
        }
    },
    mounted() {
        let vm = this;
        vm.chart = echarts.init(vm.$refs.chart);
        vm.chart.setOption(vm.option);
        window.addEventListener('resize', vm.chart.resize());
    },
    methods:{
        init(){
            let vm = this;
            vm.loading = true;
            if(vm.userdata){
                let ylnldf = (vm.userdata.ylnldf*100).toFixed(0);
                let fxkzdf = (vm.userdata.fxkzdf*100).toFixed(0);
                let scgzdf = (vm.userdata.scgzdf*100).toFixed(0);
                let sldf = (vm.userdata.sldf*100).toFixed(0);
                let wdxdf = (vm.userdata.wdxdf*100).toFixed(0);
                vm.option.series[0].data[0].value = [ylnldf, fxkzdf, scgzdf, sldf, wdxdf];
                vm.option.radar.axisName.formatter = function (value, indicator) {
                    switch (value) {
                        case '盈利能力':
                            return value + '(' + ylnldf + ')';
                        case '风险控制':
                            return value + '(' + fxkzdf + ')';
                        case '市场感知':
                            return value + '(' + scgzdf + ')';
                        case '胜率':
                            return value + '(' + sldf + ')';
                        case '稳定性':
                            return value + '(' + wdxdf + ')';
                        default:
                            break;
                    }
                }
                vm.chart.setOption(vm.option)
            }
            vm.loading = false;
        }
    }
}
</script>
<style scoped src=".././style.css"></style>
<style scoped>
.echart {
  width: 100%;
  height: 100%;
}
.about {
    position: absolute;
    right: 25px;
    background: #82b2fa;
    color: #fff;
    font-size: 13px;
    z-index: 10;
}
.about.a1 {
    width: 33px;
    height: 20px;
    line-height: 20px;
    top: 17px;
    cursor: pointer;
    user-select: none;
}
.about.a2 {
    top: 37px;
    width: 365px;
    padding: 10px 5px;
    text-align: justify;
}
.about.a2 .name {
    text-align: justify;
    text-justify: distribute-all-lines;
    text-align-last: justify;
    width: 58px;
    flex-shrink: 0;
}
</style>
<template>
 <!-- :span-method="arraySpanMethod" -->
<div style="position: relative;" :class="{high:hidemore}">
    <el-table
        class="rankingTable"
        :span-method="arraySpanMethod"
        @cell-click="handle"
        @header-click="handleheader"
        @cell-mouse-enter="mouseover"
        @cell-mouse-leave="mouseleave"
        :data="tableData"
        v-loading="showloading"
        v-if="active=='0' && hidemore"
        key="zhTable"
        >
            <el-table-column prop="RANKID" label="排名" min-width='35'></el-table-column>
            <el-table-column label="昵称" min-width='70'>
                <template slot-scope="scope">{{getName(scope.row.NC,scope.row.ZJZH)}}</template>
            </el-table-column>
            <el-table-column prop="ZHDF"  class-name="lightyellow" label="综合得分" width='45'>
                <template slot-scope="scope">{{scope.row.ZHDF}}</template>
            </el-table-column>
            <el-table-column prop="PCCS"  class-name="lightyellow" label="平仓笔数" width='40'></el-table-column>
            <el-table-column prop="YKJE"  class-name="lightyellow"  label="累计盈利额"></el-table-column>
            

            <el-table-column prop="ZRQY" label="当日期初权益"></el-table-column>
            <el-table-column prop="SZQY"  label="当日期末权益"></el-table-column>
            <el-table-column prop="JZSYL" label="净值收益率" min-width='50'>
                <template slot-scope="scope">{{scope.row.JZSYL == "0"?scope.row.JZSYL:parseInt(scope.row.JZSYL*100)+'%'}}</template>
            </el-table-column>

            <!-- <el-table-column prop="SUMSYLDIFF" label="隔日收益率差和" width='60'></el-table-column>
            <el-table-column prop="YKHCTS" label="回撤天数" width='40'></el-table-column>
            <el-table-column prop="YLCS" label="盈利次数" width='45'></el-table-column>
            <el-table-column prop="CPCS" label="持平次数" width='45'></el-table-column>
            <el-table-column prop="SYTS" label="收益率增幅天数" width='60'></el-table-column> -->

            <el-table-column prop="YLNLDF" label="盈利能力得分" min-width='55'></el-table-column>
            <el-table-column prop="FXKZDF" label="风险控制得分" min-width='55'></el-table-column>
            <el-table-column prop="WDXDF" label="稳定性得分" min-width='55'></el-table-column>
            <el-table-column prop="SLDF" label="胜率得分" min-width='45'></el-table-column>
            <el-table-column prop="SCGZDF" label="市场感知得分" min-width='55'></el-table-column>
            
             <!-- @cell-mouse-enter="mouseover" -->
            <el-table-column prop="" class-name="lightcilck otherlight" label="算法其他相关数据" width='80'  v-if="!showcol">
                <template>
                    <div 
                        @click="open"
                        class="showlist"
                    >  
                    <div>点<br>击<br>展<br>开<br>更<br>多<br>交<br>易<br>数<br>据</div>
                        <!-- <div>点击</div>
                        <div>展开</div>
                       <div class="img"></div> -->
                    </div>
                </template>
            </el-table-column>

            <el-table-column prop="SUMSYLDIFF" class-name="lightcilck" v-if="showcol" label="隔日收益率差和" width='60'></el-table-column>
            <el-table-column prop="YKHCTS" class-name="lightcilck" v-if="showcol" label="回撤天数" width='40'></el-table-column>
            <el-table-column prop="YLCS" class-name="lightcilck" v-if="showcol" label="盈利次数" width='45'></el-table-column>
            <el-table-column prop="CPCS" class-name="lightcilck" v-if="showcol" label="持平次数" width='45'></el-table-column>
            <el-table-column prop="SYTS" class-name="lightcilck lastlight" v-if="showcol" label="收益率增幅天数" width='80'></el-table-column>
            <div slot="empty">
                {{zjzh != ' '? '未找到该昵称':'敬请期待...'}} 
            </div>
    </el-table>
    <!-- <div class="jian" v-if="active=='0' && showcol && hidemore" @click="close"></div> -->

    <el-table
        class="rankingTable"
        :data="tableData"
        v-loading="showloading"
        v-if="active=='0' && !hidemore"
        key="zhTable"
        >
            <el-table-column prop="RANKID" label="排名" min-width='35'></el-table-column>
            <el-table-column label="昵称" min-width='70'>
                <template slot-scope="scope">{{getName(scope.row.NC,scope.row.ZJZH)}}</template>
            </el-table-column>
            <el-table-column prop="ZHDF"   class-name="lightyellow" label="综合得分" min-width='50'>
                <template slot-scope="scope">{{scope.row.ZHDF}}</template>
            </el-table-column>
            <el-table-column prop="PCCS"   class-name="lightyellow" label="平仓笔数" width='40'></el-table-column>
            <el-table-column prop="YKJE"   class-name="lightyellow"  label="累计盈利额"></el-table-column>


            <el-table-column prop="ZRQY" label="当日期初权益"></el-table-column>
            <el-table-column prop="SZQY"  label="当日期末权益"></el-table-column>
            <el-table-column prop="JZSYL" label="净值收益率" min-width='60'>
                <template slot-scope="scope">{{scope.row.JZSYL == "0"?scope.row.JZSYL:parseInt(scope.row.JZSYL*100)+'%'}}</template>
            </el-table-column>
<!-- 
            <el-table-column prop="SUMSYLDIFF" label="隔日收益率差和" width='60'></el-table-column>
            <el-table-column prop="YKHCTS" label="回撤天数" width='40'></el-table-column>
            <el-table-column prop="YLCS" label="盈利次数" width='45'></el-table-column>
            <el-table-column prop="CPCS" label="持平次数" width='45'></el-table-column>
            <el-table-column prop="SYTS" label="收益率增幅天数" width='60'></el-table-column> -->

            <el-table-column prop="YLNLDF" label="盈利能力得分" min-width='70'></el-table-column>
            <el-table-column prop="FXKZDF" label="风险控制得分" min-width='70'></el-table-column>
            <el-table-column prop="WDXDF" label="稳定性得分" min-width='60'></el-table-column>
            <el-table-column prop="SLDF" label="胜率得分" min-width='50'></el-table-column>
            <el-table-column prop="SCGZDF" label="市场感知得分" width='60'></el-table-column>
            <div slot="empty">
                {{zjzh != ' '? '未找到该昵称':'敬请期待...'}} 
            </div>
            
    </el-table>

    <el-table
        class="rankingTable rnykb"
        :data="tableData"
        v-loading="loading"
        v-if="(active=='1' && daytype == '2') || active == '2'"
        key="ykbTable"
        >
            <el-table-column prop="RANKID" width="55" label="序号" v-if="xu"></el-table-column>
            <el-table-column prop="RANKID" width="55" label="排名" v-if="!xu"></el-table-column>
            <el-table-column label="昵称">
                <template slot-scope="scope">{{getName(scope.row.NC,scope.row.ZJZH)}}</template>
            </el-table-column>
            <el-table-column  class-name="lightyellow" label="日内盈亏比">
                <template slot-scope="scope">{{scope.row.YKB == '999999' ? '999...' : scope.row.YKB}}</template>
            </el-table-column>
            <el-table-column  class-name="lightyellow" prop="jycs" width="80" label="平今笔数"></el-table-column>
            <el-table-column  class-name="lightyellow" prop="pcyk" width="90" label="平今收益"></el-table-column>

            <!-- <el-table-column  prop="DRYL" label="净利润"></el-table-column> -->
<!-- 
            <el-table-column prop="zrqy" label="当日期初权益"></el-table-column>
            <el-table-column prop="szqy" label="当日期末权益 "></el-table-column>
            <el-table-column prop="DRJZ" label="当日净值"></el-table-column> -->

            <el-table-column prop="YLJE" min-width="65" label="总盈利"></el-table-column>
            <el-table-column prop="YLCS" width="70" label="盈利次数"></el-table-column>
            <el-table-column prop="KSJE" min-width="65" label="总亏损"></el-table-column>
            <el-table-column prop="KSCS" width="70" label="亏损次数"></el-table-column>


            <el-table-column prop="szqy" label="当日权益 "></el-table-column>
            <el-table-column prop="DRJZ" label="当日净值"></el-table-column>
            <div slot="empty">
               {{zjzh != ' '? '未找到该昵称':'敬请期待...'}} 
            </div>
            
    </el-table>
    <el-table
        class="rankingTable"
        :data="tableData"
        v-loading="loading"
        v-if="(active=='1' && daytype == '3') || active == '3'"
        key="slTable"
        >
            <el-table-column prop="RANKID" width="55" label="序号" v-if="xu"></el-table-column>
            <el-table-column prop="RANKID" width="55" label="排名" v-if="!xu"></el-table-column>
            <el-table-column label="昵称">
                <template slot-scope="scope">{{getName(scope.row.NC,scope.row.ZJZH)}}</template>
            </el-table-column>
            <el-table-column  class-name="lightyellow" label="日内胜率">
                <template slot-scope="scope">{{parseInt(scope.row.SL * 100)}}%</template>
            </el-table-column>
            <el-table-column  class-name="lightyellow" prop="JYCS" width="80" label="平今笔数"></el-table-column>
            <el-table-column  class-name="lightyellow" prop="pcyk" width="90" label="平今收益"></el-table-column>
            <!-- <el-table-column prop="DRYL" label="净利润"></el-table-column> -->

            <!-- <el-table-column prop="zrqy" label="当日期初权益"></el-table-column> -->
           
            <el-table-column prop="YLCS" width="80" label="盈利笔数"></el-table-column>
            <el-table-column prop="KSCS" width="80" label="亏损笔数"></el-table-column>
            <el-table-column prop="CPCS" width="80" label="持平笔数"></el-table-column>

            <el-table-column prop="szqy" label="当日权益"></el-table-column>
            <el-table-column prop="DRJZ" label="当日净值"></el-table-column>
            <div slot="empty">
               {{zjzh != ' '? '未找到该昵称':'敬请期待...'}} 
            </div>
            
    </el-table>

<!-- 日内产出比 -->
      <el-table
        class="rankingTable"
        :data="tableData"
        v-loading="loading"
        v-if="(active=='1' && daytype == '1')"
        key="slTable"
        >
            <el-table-column prop="rankid" width="55" label="序号" v-if="xu"></el-table-column>
            <el-table-column prop="rankid" width="55" label="排名" v-if="!xu"></el-table-column>
            <el-table-column label="昵称">
                <template slot-scope="scope">{{getName(scope.row.nc,scope.row.ZJZH)}}</template>
            </el-table-column>
            <el-table-column  class-name="lightyellow" label="日内产出比">
                <template slot-scope="scope">{{parseInt(scope.row.ccb)}}</template>
            </el-table-column>
            <el-table-column  class-name="lightyellow" prop="jycs"  label="平今笔数"></el-table-column>
            <el-table-column  class-name="lightyellow" prop="pcyk"  label="平今收益"></el-table-column>
            <!-- <el-table-column prop="DRYL" label="净利润"></el-table-column> -->

            <!-- <el-table-column prop="zrqy" label="当日期初权益"></el-table-column> -->
           
            <el-table-column prop="sxf" label="日内手续费"></el-table-column>

            <el-table-column prop="szqy" label="当日权益"></el-table-column>
            <el-table-column prop="drjz" label="当日净值"></el-table-column>
            <div slot="empty">
               {{zjzh != ' '? '未找到该昵称':'敬请期待...'}} 
            </div>
            
    </el-table>

    <el-table
        class="rankingTable"
        :data="tableData"
        v-loading="loading"
        v-if="(active=='1' && daytype == '')"
        key="slTable"
        >
            <el-table-column prop="RANKID" width="55" label="序号" v-if="xu"></el-table-column>
            <el-table-column prop="RANKID" width="55" label="排名" v-if="!xu"></el-table-column>
            <el-table-column label="昵称">
                <template slot-scope="scope">{{getName(scope.row.NC,scope.row.ZJZH)}}</template>
            </el-table-column>
            <el-table-column  class-name="lightyellow" label="日内胜率">
                <template slot-scope="scope">{{parseInt(scope.row.SL * 100)}}%</template>
            </el-table-column>
            <el-table-column  class-name="lightyellow" prop="JYCS" width="80" label="平今笔数"></el-table-column>
            <el-table-column  class-name="lightyellow" prop="pcyk" width="90" label="平今收益"></el-table-column>
            <!-- <el-table-column prop="DRYL" label="净利润"></el-table-column> -->

            <!-- <el-table-column prop="zrqy" label="当日期初权益"></el-table-column>
            <el-table-column prop="szqy" label="当日期末权益"></el-table-column>
            <el-table-column prop="DRJZ" label="当日净值"></el-table-column> -->
            <el-table-column prop="YLCS" width="80" label="盈利笔数"></el-table-column>
            <el-table-column prop="KSCS" width="80" label="亏损笔数"></el-table-column>
            <el-table-column prop="CPCS" width="80" label="持平笔数"></el-table-column>

            <el-table-column prop="szqy" label="当日权益"></el-table-column>
            <el-table-column prop="DRJZ" label="当日净值"></el-table-column>
            <div slot="empty">
               {{zjzh != ' '? '未找到该昵称':'敬请期待...'}} 
            </div>
            
    </el-table>

</div>
</template>
<script>
let time = null
export default {
    data:()=>({
        showcol:false,//默认不展开
        showloading:false
    }),
    props:{
        tableData: Array,
        loading: Boolean,
        active:String,
        daytype:{
            type:String,
            default:''
        },
        hidemore:{
            type:Boolean,
            default:false
        },
        xu:{
            type:Boolean,
            default:false
        },
        zjzh:{
            type:String,
            default:' '
        }
    },
    watch:{
        loading(val){
            this.showloading = val
        }
    },

    methods:{
        //nc为‘ ’，则whid中间4位用*
        getName(nc,ZJZH){
            if(nc==' ' && ZJZH){
                return ZJZH.substr(0,3) + '*****' + ZJZH.substr(8)
            }else{
                return nc
            }
        },
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            if(!this.showcol){
                // if(columnIndex == 13){
                //     return [15,1]
                // }
                if(columnIndex == 13){
                    if(rowIndex === 0){
                        return [15,1]
                    }else{
                        return [0,0]
                    }
                }
            }
        },
        totalhandle(column){
            if(column.label == '算法其他相关数据'){
                this.showloading = true
                time && clearTimeout(time)
                time = setTimeout(()=>{
                    this.showloading = false
                    this.showcol = true
                },200)
            }
            if(column.label == '收益率增幅天数'){
                this.close()
            }
        },
        handle(row,column,cell,event) {
            this.totalhandle(column)
            // if(column.label == '算法其他相关数据'){
            //     this.showloading = true
            //     time && clearTimeout(time)
            //     time = setTimeout(()=>{
            //         this.showloading = false
            //         this.showcol = true
            //     },200)
            // }
        },
        handleheader(column,event){
            this.totalhandle(column)
        },
        mouseover(row,column,cell,event){
            if(column.label == '算法其他相关数据'){
                cell.querySelector('.showlist').classList.add('newActive')
            }
        },
        mouseleave(row,column,cell,event){
            if(column.label == '算法其他相关数据'){
                cell.querySelector('.showlist').classList.remove('newActive')
            }
        },

        open(){
            // this.showloading = true
            // time && clearTimeout(time)
            // time = setTimeout(()=>{
            //     this.showloading = false
            //     this.showcol = true
            // },200)
        },
        close(){
            // this.showcol = false
            this.showloading = true
            time && clearTimeout(time)
            time = setTimeout(()=>{
                this.showloading = false
                this.showcol = false
            },200)
        }
      
    }
}
</script>
<style scoped>

/* 表格样式 */
.rankingTable {
    /* font-family: SimSun; */
    font-size:12px;
    color:#51555c;
    border-bottom:1px solid rgba(111,156,214,0.6);
}
.rankingTable >>> thead th {
    background-color: #6F9CD6;
    color: #fff;
    border-right: 1px solid #fff;
    padding: 5px 0;
}
.rankingTable >>> thead .cell {
    line-height: 18px;
    padding-left: 5px!important;
    padding-right: 5px!important;
    font-weight: normal;
}
.rankingTable >>> tbody .cell {
    padding-left: 0px!important;
    padding-right: 0px!important;
}
.rankingTable >>> tbody tr:nth-child(2n) {
    background-color: #F0F6FF;
}
.rankingTable >>> tbody tr:nth-child(2n+1) {
    background-color: #FAFCFF;
}
.rankingTable >>> .cell {
    text-align: center;
}
.rankingTable >>> tbody td {
    border: none;
}
.rankingTable >>> thead th.lastlight{
    padding-right:20px;
    cursor: pointer;
}
.rankingTable >>> thead th.lastlight::after{
    content: '';
    position: absolute;
    width: 20px;
    top: 0;
    bottom: 0;
    background-color:red;
    background: url(../assets/image/ranking/zuo.png) no-repeat center center #9abde9;
    background-size: 10px 12px;
    cursor: pointer;
}
.rankingTable >>> thead th.otherlight{
    padding-right:20px;
    position: relative;
    cursor: pointer;
}
.rankingTable >>> thead th.otherlight::after{
    content: '';
    position: absolute;
    width: 20px;
    top: 0;
    bottom: 0;
    background-color:red;
    background: url(../assets/image/ranking/you.png) no-repeat center center #9abde9;
    background-size: 10px 12px;
    cursor: pointer;
}
/* .rankingTable >>> tbody tr .lightcilck{
    background-color: rgba(94,157,253,0.04);
}
.rankingTable >>> thead th.lightcilck{
    background-color: rgba(151,172,199,0.7);
} */
.rankingTable >>> thead th.lightyellow {
    background-color:#386db1;
}

.showlist div{
    line-height: 16px;
    color: rgba(96, 126, 181, 0.6);
    cursor: pointer;
    user-select: none;
    font-size: 13px;
}
.newActive div{
     color: #3888ff;
}
.newActive .img{
   width:20px;
    height: 20px;
    background: url(../assets/image/ranking/K_01.png) no-repeat center center;
	background-size: contain;
    margin: 5px auto 0;
}

/* 新增收起箭头 */
/* .jian{
    position: absolute;
    right: 1px;
    top: 3px;
    width: 12px;
    height: 36px;
    background: url(../assets/image/ranking/H_00.png) no-repeat center center;
	background-size: contain;
}
.jian:hover{
    background: url(../assets/image/ranking/H_01.png) no-repeat center center;
	background-size: contain;
}
.img{
    width:20px;
    height: 20px;
    background: url(../assets/image/ranking/K_00.png) no-repeat center center;
	background-size: contain;
    margin: 5px auto 0;
} */
.high .rankingTable{
    min-height: 450px;
}
.rnykb >>> .el-table__header,.rnykb >>> .el-table__body{
 width: 100% !important;
}
</style>
import Table from '@/components/Table.vue'
import blueTab from '../../components/blueTab.vue'
import { GetRankList, GetMonthList, GetYLERankList, GetYKBRankList, GetSLRankList, GetHCBRankList, GetDayRankList } from '../../assets/js/request'

export default {
    data: () => ({
        tabActive: '-1',
        // activetabindex:0,//默认选中的tab index
        realTabActive:'1', //真正的选中的tab，目的是tab切换时，使数据加载完毕再切换tab
        active:'-1', //从首页传入 0  月冠军  1排行榜
        tabList: [
            {
                name: '比赛日',
                value: '1'
            },
            {
                name: '月冠军',
                value: '0'
            }
       
        //  {
        //     name: '“比赛日”盈亏比',
        //     value: '2'
        // }, {
        //     name: '“比赛日”胜率',
        //     value: '3'
        // }
        ],
        // cmonth: ' ',
        month: ' ', //月份
        monthOptions: [], //月份下拉选项列表
        zjzh: '', //昵称
        bsrday:'',//比赛日回显的日期

        loading: false,
        tableData: [],
        page: 1,
        total: 0, //页码
        pagesize: 15,
        searchActive: false,
        bsrbool:false, //是否点击进入比赛日
        daytype: '', //比赛日指定指标字段 1 - (对应的是日内产出比) 2 - 盈亏比 3 - 胜率
        showDayList:true, //是否显示比赛日排行
        type: 4 , //入参增加type，默认可以传4，如果筛选了，按筛选的值传 1-日内产出比 2-盈亏比 3-胜率  4-返回当前比赛日指定指标的数据
        name:[
            { text:'胜率', type:3},
            { text: '盈亏比' ,type:2},
            { text: '日内产出比' ,type:1}
        ],
        selename:'胜率'
    }),
    components: {
        Table,
        blueTab
    },
    created() {
        // this.acitve = this.$route.params ? this.$route.params.acitve : '-1'
        // console.log(this.acitve)
        this.active = this.$route.query ? this.$route.query.acitve : '-1'
        this.realTabActive = this.active || '1';
        if(this.active == '0'){
            this.tabClick(this.tabList[1])
        }else{
            this.tabClick(this.tabList[0])
        }
    },
    watch: {
        tabActive(val,oldVal) {
            let vm = this
            // if (val == '1') {
            //     vm.getList(1)
            // } else {
                vm.loading = true;
                new Promise((resolve, reject) => {
                    vm.GetMonthList(resolve)
                }).then(()=>{
                    return new Promise((resolve)=>{
                        vm.getList(1,resolve)
                    })
                }).then(()=>{
                    if(oldVal!=-1){
                        vm.$refs.tableTab.setTabValue(vm.tabActive);
                        vm.realTabActive = vm.tabActive;
                    }
                })
            // }
        }
    },
    methods: {
        async chanagename(val){
            this.type = val
            this.getList(1)
        },
        async getList(val,resolve) {
            let vm = this;
            vm.loading = true;
            val && (vm.page = val)
            let params = {
                month: vm.month,
                zjzh: vm.zjzh=='' ? ' ' : vm.zjzh,
                Page: vm.page,
                PageSize: vm.pagesize,
                type:vm.type
            }
            switch (vm.tabActive){
                case '0':
                    var {data,status} = await GetRankList(params);
                    break;
                case '1':
                    // var {data,status} = await GetYLERankList(params);
                    // var { data, status } = await GetHCBRankList(params);
                    /// 类型：1-日内产出比 2-盈亏比 3-胜率
                    var { data, status } = await GetDayRankList(params)
                    break;
                case '2':
                    var {data,status} = await GetYKBRankList(params);
                    break;
                case '3':
                    var {data,status} = await GetSLRankList(params);
                    break;
                // case '4':
                //     // var {data,status} = await GetYLERankList(params);
                //     var { data, status } = await GetHCBRankList(params);
                //     break;
            }
            vm.loading = false;
            if (vm.tabActive == '1'){
                // vm.bsrbool = true
                if (status) {
                    // vm.tabActive = data.type + ""
                    vm.daytype = data.type + ""
                    if(vm.daytype == '1'){
                        vm.selename = '日内产出比'
                    } else if (vm.daytype == '2'){
                        vm.selename = '盈亏比'
                    } else if (vm.daytype == '3') {
                        vm.selename = '胜率'
                    }


                    if (data.type == 1){
                        //类型：1 - 最大回撤比 2 - 盈亏比 3 - 胜率
                        /// 盈亏比 ykbrank 
                        /// 胜率slrank 
                        /// 最大回撤比 hcbrank 
                        vm.tableData = data.ccbrank.list;
                        vm.total = data.ccbrank.ntotal;
                    } else if (data.type == 2){
                        vm.tableData = data.ykbrank.list;
                        vm.total = data.ykbrank.ntotal;
                    }else if(data.type == 3){
                        vm.tableData = data.slrank.list;
                        vm.total = data.slrank.ntotal;
                    }
                    vm.bsrday = data.gameday ? (data.gameday.substr(0, 4) + '-' + data.gameday.substr(4, 2) + '-' + data.gameday.substr(6, 2)) :''
                } else {
                    vm.tableData = [];
                    vm.total = 0;
                    vm.daytype = ''
                    // vm.tabActive = '3'
                    // vm.showDayList = false
                }
            }else{
                // vm.bsrbool = false
                if (status) {
                    vm.tableData = data.list;
                    vm.total = data.ntotal;
                } else {
                    vm.tableData = [];
                    vm.total = 0;
                }
            }
            resolve && resolve()
        },
        async GetMonthList(resolve){
            let vm = this;
            vm.monthOptions = [];
            let param = {
                type: vm.tabActive
                // type: (vm.tabActive == '0' || vm.tabActive == '1') ? 0 : 1
            }
            let {data,status} = await GetMonthList(param);
            if(status){
                if(data && data.length != 0){
                    //初始化时，日期默认上个结算日/月
                    // vm.cmonth = (data && data[0].rqname) || ' '
                    vm.month = (data && data[0].mon) || ' '
                    // vm.month = (data[data.length-1]&&data[data.length-1].mon) || ' '
                    vm.monthOptions = data.map(item => {
                        item.value = item.mon;
                        item.mon = item.rqname
                        // item.mon = (vm.tabActive == '0') ? (item.mon.substr(0, 4) + '-' + item.mon.substr(4, 2)) : (item.mon.substr(0, 4) + '-' + item.mon.substr(4, 2) + '-' + item.mon.substr(6, 2))
                        return item;
                    });
                }
                
            }else{
                vm.monthOptions = [];
            }
            resolve && resolve()
        },
        tabClick(item){
            let vm = this;
            vm.total = 0;
            vm.tabActive = item.value;
            // if(item.value == '1'){
            //     vm.getList(1)
            // }else{
            //     new Promise((resolve, reject) => {
            //         vm.GetMonthList(resolve)
            //     }).then(function () {
            //         vm.getList(1)
            //     })
            // }
           
        },
        currentChange(val){
            let vm = this;
            vm.page = val;
            // if (vm.bsrbool){
            //     vm.tabActive = "1"
            // }
            vm.getList()
        },
        //nc为‘ ’，则whid中间4位用*
        getName(item){
            if(item.nc==' '){
                return item.ZJZH.substr(0,3) + '****' + item.ZJZH.substr(7)
            }else{
                return item.nc
            }
        },
        //获得更新日期
        getdate(tableData){
            // let date = tableData[0].bcrq || tableData[0].BCRQ;
            let date = tableData[0].rq || tableData[0].BCRQ;
            if (date){
                return date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2)
            }else{
                return "--"
            }

            
        }
    }
}
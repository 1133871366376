
import login from "./subPage/login"
import confirmation from "./subPage/confirmation"
import center from "./subPage/center"
import modifyName from "./subPage/modifyName"
import naHua from "./subPage/naHua"
import quit from "./subPage/quit"
import buy from "./subPage/buy"
import bill from "./subPage/bill"
import billBuy from "./subPage/billBuy"
export default {
    data: () => ({
        loginshow: true, //是否显示登录页面
        comfirm: false, // 是否显示实名认证弹框
        modify: false, // 是否显示修改昵称弹框
        loading: false,
        whid: '',
        // dialog: {
        //     title:'', //弹窗标题
        //     content:'', //弹窗主体
        // },
        // show:true
        buyshow: false, //是否显示购买弹框
        modifyshow: false, //修改昵称
        comfirmshow: false, //实名认证弹框
        quitshow: false, //退赛
        listshow: false, //历史账单
        confirm: '', //是否实名认证
        ischangename: false, //是否修改了昵称,
        listbuyshow:false, //购买记录是否显示
        nahuashow:false, //南华期货
        pricedata:{} //获奖信息
    }),
    components: {
        login,
        confirmation,
        center,
        modifyName,
        quit,
        buy,
        bill,
        billBuy,
        naHua
    },

    created() {
        let vm = this;
        //判断显示登录页面还是个人中心
        let whid = localStorage.getItem('whid');
        this.confirm = localStorage.getItem('confirm');
        if (this.confirm == 0){
            vm.comfirmshow = true
        }
        vm.loginshow = whid && whid!='' ? false : true;
        vm.whid = whid && whid!='' ? whid : '';
    },
    methods:{
        load(val){
            this.loading=val;
        },
        showDialog(title,content,data){
            let vm = this;
            // vm.dialog.title = title;
            // vm.dialog.content = content;
            // vm.show = true;
            if (content == 'buy'){
                vm.buyshow = true
                return false
            }
            if (content == 'modifyName'){
                vm.modifyshow = true
                return false
            }
            if (content == 'confirm'){
                vm.comfirmshow = true
                return false
            }
            if (content == 'quit') {
                vm.quitshow = true
                return false
            }
            if (content == 'bill'){
                vm.listshow = true
                return false
            }
            if (content == 'billBuy') {
                vm.listbuyshow = true
                return false
            }
            if (content == 'naHua') {
                vm.nahuashow = true
                if(data){
                    vm.pricedata = data
                    console.log(vm.pricedata)
                }
                return false
            }
        }
    }
}
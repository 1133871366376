<template>
    <div class="flex">
        <div class="reportleft">
            <div class="reportimg" :class="{active: whid!=''}"></div>
            <!-- <div class="reportcircle" v-if="whid!=''">{{username}}</div> -->
            <div class="reportcircle" v-if="phone!=''">{{username}}</div>
            <div class="reportsmall" :class="{active:whid!=''}" @click="login">{{whid!='' ? '退出登录' : '登录查看'}}</div>
        </div>
        <div class="reportright flex">
            <div class="flex" style="justify-content: space-around;">
                <report :numobj="userdata && format(userdata.jyts)" :text="'报告天数'"></report>
                <report :numobj="userdata && format(userdata.jycs)" :text="'交易笔数'"></report>
                <report :numobj="userdata && format(userdata.csqy)" :text="'初始权益'"></report>
                <report :numobj="userdata && format_per(userdata.fxd)" :text="'风险度'" style="min-width: 73px;"></report>
                <report :numobj="userdata && format(userdata.sy)" :text="'收益额'" style="min-width: 67px;"></report>
            </div> 
            <div class="flex" style="justify-content: space-around; margin-top:30px;margin-top: 27px;">
                <report :numobj="userdata && format_per(userdata.syl)" :text="'收益率'"></report>
                <report :numobj="userdata && format_per(userdata.sl)" :text="'逐日胜率'"></report>
                <report :numobj="userdata && formatdot(userdata.ykb)" :text="'盈亏比'"></report>
                <report :numobj="userdata && format(userdata.sxf)" :text="'手续费'" style="min-width: 73px;"></report>
                <div style="min-width: 67px;padding-top: 1px;">
                    <span class="viewmore" @click="change">查看更多</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import report from './report.vue'
import main from "@/assets/js/main.js";
import { NicknameGet } from "../../../assets/js/request";
export default {
    components:{
        report
    },
    props:{
        userdata: Object,
        whid: String
    },
    data(){
        return {
            username:'',
            phone:''
        }
    },
    created(){
        this.nickname()
        this.phone = localStorage.getItem('phone')
    },
    methods:{
        change(){
            this.$router.push('/personalCenter')
        },
        format(val){
            let vm = this;
            return vm.userdata ? main.handleNum(val) : {num:"",unit:""}
        },
        formatdot(val){
            let vm = this;
            if(vm.userdata){
               if(val.ykb == '999999'){
                 return {num:"999...",unit:""}
               }else{
                 return main.handleNum(val)
               }
            }else{
                return {num:"",unit:""}
            }
            
        },
        format_per(val){
            let vm = this;
            let num = val*100;
            num = num.toFixed(2);
            return vm.userdata ? {num:String(num),unit:"%"} : {num:"",unit:""}
        },
        login(){
            let vm = this;
            if(vm.whid==''){ //此时按钮为登录查看，点击跳到个人中心
                // vm.$router.push('/personalCenter')
                vm.$router.push({
                    path: "/personalCenter",
                    query: {
                        from: 'home'
                    }
                });
            }else{ //此时按钮为退出登录
                localStorage.setItem("token", '')
                localStorage.setItem("whid", '')
                localStorage.setItem("phone", '')
                location.reload();
            }
        },
        //昵称获取
        async nickname(){
            let vm = this
            if(vm.whid=='') return
            let phone = localStorage.getItem('phone')
            let data = await NicknameGet({whid:vm.whid,phonenum: phone})
            if(data.status){
                 //获取昵称
                 if(data.data.nc.trim()){
                    vm.username = data.data.nc;
                 }else{
                    // vm.username = vm.whid;
                    vm.username = vm.phone
                 }
            }else{
                // vm.username = vm.whid;
                vm.username = vm.phone
            }
        },
    }
}
</script>
<style scoped src=".././style.css"></style>

<template>
    <div class="bill">
        <el-dialog class="dialog" title="购买记录"  width="546px" top='30vh' :visible.sync="show" @close="close">
            <div v-loading='loading'>
                <div class="line"></div>
                <div class="billpanel">
                        <el-date-picker
                        v-model="month"
                        type="year"
                        placeholder="选择年">
                        </el-date-picker>
                        <div class="btn" style="margin:0 15px;padding:6px 12px;" @click="checkbill">查询</div>
                </div>
                <div v-if="!detail">
                    <div class="billtitle bw">
                        <div>项目</div>
                        <div>金额（元）</div>
                    </div>
                    <vue-scroll style="height:300px;">
                        <div v-if="fundDetail.length == 0" style="text-align:center;padding:30px 0;">
                            暂无数据
                        </div>
                        <div v-else style="border-bottom: 1px solid #d3d3d3;">
                            <div v-for="(item, index) in fundDetail" :key="index">
                                <div class="td_top1">{{parseInt(item.date.substring(4,6))+"月"+parseInt(item.date.substring(6,8))+"日"}}</div>
                                <div v-for="(obj , i) in item.list" :key="i" class="douter">
                                    <div class="dleft" v-html="obj.flag"></div>
                                    <div class="dright">{{obj.fsje}}</div>
                                </div>
                            </div>
                            <!-- <table class="specificTable linetable" cellpadding="0" cellspacing="0">
                                <tr v-for="(item, index) in fundDetail" :key="index">
                                    <td class="td_top1" colspan="2">{{parseInt(item.date.substring(4,6))+"月"+parseInt(item.date.substring(6,8))+"日"}}</td>
                                </tr>
                            </table> -->
                        </div>
                    </vue-scroll>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
</script>
<script>
import { GetFundDetail,DayDetailJson,GetBillData} from "../../../assets/js/request";
export default {
    name:"bill",
    data(){
        return{
            isread:false,
            month:'',
            show:false,
            fundDetail:[],
            dayDetail:[],
            loading:false,
            detail:false //是否显示账单详情
        }
    },  
   props:{
        listbuyshow:{
            type:Boolean,
            default:false
        },
        whid:String
    },
    watch:{
        listbuyshow(val){
            this.show = val
        }
    },
    created() {
        this.gettime()
  
    },
    methods: {
        // 获取当前年月
        gettime(){
            let date = new Date()
            this.month = date
            // this.month = date.getFullYear()+ '-' + (date.getMonth() + 1)
            this.checkbill()
        },
        async checkbill(){
            if(!this.month) return
            this.loading = true
            let smonth = this.month.getFullYear()+""
            //  0 全部类别 1 充值购买 2 赠送 3 激活赠送/系统赠送 4 盈利送红包 5 退款/原赠豆扣除 6 模拟交易消耗
            let data = await GetBillData({whid:this.whid,startMonth:smonth,page:1,pagesize:500,cxlx:[1,2,3,4,5]})
            this.loading = false
            this.fundDetail = data.tableList
        },
        close(){
            this.$emit("update:listbuyshow",false)
        },
        async question(rq){
            this.dayDetail = []
            this.detail = true
            this.loading = true
            let data = await DayDetailJson({whid:this.whid,rq:rq,page:1,pagesize:500})
            this.loading = false
            this.dayDetail = data.fundDetail;
        },
        goback(){
            this.detail = false

        }

    }
}
</script>
<style scoped src="./style.css"></style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import homePage from '../views/homePage' //首页
import ranking from '../views/ranking' //大赛排行
import software from '../views/software' //模拟软件  交易软件
import tradeRules from '../views/tradeRules' //交易规则
import description from '../views/description' //大赛说明
import mimeticBean from '../views/mimeticBean' //模拟豆
import personalCenter from '../views/personalCenter' //个人中心
import result from '../views/result' //支付结果页面
import winnerList from '../views/winnerList' //获奖名单

Vue.use(VueRouter)

const routes = [
  {path: '*', redirect: '/homePage'},
  {
    path: '/homePage',
    name: 'homePage',
    component: homePage
  },
  {
    path: '/ranking',
    name: 'ranking',
    component: ranking,
    meta: {
      title: '排行榜',
    }
  },
  {
    path: '/software',
    name: 'software',
    component: software,
    meta: {
      // title: '模拟软件',
      // title: '交易软件',
      // title:'参赛软件'
      title: '交易软件'
    }
  },
  {
    path: '/tradeRules',
    name: 'tradeRules',
    component: tradeRules,
    meta: {
      title: '交易规则',
    }
  },
  {
    path: '/description',
    name: 'description',
    component: description,
    meta: {
      title: '活动说明',
    }
  },
  {
    path: '/mimeticBean',
    name: 'mimeticBean',
    component: mimeticBean,
    meta: {
      title: '模拟豆',
    }
  },
  {
    path: '/personalCenter',
    name: 'personalCenter',
    component: personalCenter,
    meta: {
      title: '个人中心',
    }
  },
  {
    path: '/result',
    name: 'result',
    component: result,
    meta: {
      title: '支付结果',
    }
  },
  {
    path: '/winnerList',
    name: 'winnerList',
    component: winnerList,
    meta: {
      title: '获奖名单',
    }
  }
]

const router = new VueRouter({
  mode: 'hash',   
  routes
})

export default router

<template>
<div style="overflow-x:auto">
    <div class="rankTitle">月冠军</div>
    <table class="rank"  cellspacing="0" cellpadding="0" style="min-width:1000px">
        <thead>
            <th style="min-width:50px">月份</th>
            <th style="min-width:40px">排名</th>
            <th style="min-width:45px">综合得分</th>
            <th style="min-width:40px">平仓笔数</th>
            <th style="min-width:50px">当月持仓天数</th>

            <th style="min-width:80px">累计盈利额</th>
            <th style="min-width:85px">当日期初权益</th>
            
            <th style="min-width:85px">当日期末权益</th>
            <th style="min-width:45px">净值收益率</th>
            <th style="min-width:60px">隔日收益率差和</th>
            <th style="min-width:40px">回撤天数</th>
            <th style="min-width:40px">盈利次数</th>
            <th style="min-width:40px">持平次数</th>
            <th style="min-width:60px">收益率增幅天数</th>
            
            <th style="min-width:60px">盈利能力得分</th>
            <th style="min-width:60px">风险控制得分</th>
            <th style="min-width:55px">稳定性得分</th>
            <th style="min-width:45px">胜率得分</th>
            <th style="min-width:45px">市场感知得分</th>
        </thead>
        <tbody v-if="monlist.length>0">
            <tr v-for="(item,index) in monlist" :key="index" style="border:none;">
                <td>{{item.mon}}</td>
                <td>{{item.RANKID == "0" ? "--" : item.RANKID}}</td>
                <td>{{item.ZHDF}}</td>
                <td :class="{red:item.RANKID == '0'}">{{item.PCCS}}</td>
                <td :class="{red:item.RANKID == '0'}">{{item.ccts}}</td>
                <td>{{item.YKJE}}</td>

                <td>{{item.ZRQY}}</td>
                <td>{{item.SZQY}}</td>
                <td>{{item.JZSYL == "0"?item.JZSYL:parseInt(item.JZSYL*100)+'%'}}</td>
                <td>{{item.SUMSYLDIFF}}</td>
                <td>{{item.YKHCTS}}</td>
                <td>{{item.YLCS}}</td>
                <td>{{item.CPCS}}</td>
                <td>{{item.SYTS}}</td>
                <td>{{item.YLNLDF}}</td>
                <td>{{item.FXKZDF}}</td>
                <td>{{item.WDXDF}}</td>
                <td>{{item.SLDF}}</td>
                <td>{{item.SCGZDF}}</td>
            </tr>
        </tbody>
        <!-- <thead>
            <th style="min-width:55px">月份</th>
            <th style="min-width:35px">排名</th>
            <th style="min-width:50px">累计盈利额</th>
            <th style="min-width:30px">当日权益</th>
            <th style="min-width:30px">累计净值</th>
            <th style="min-width:63px">盈利能力得分</th>
            <th style="min-width:30px">胜率</th>
            <th style="min-width:47px">盈亏比</th>
            <th style="min-width:47px">稳定性得分</th>
            <th style="min-width:63px">权益最大回撤比</th>
            <th style="min-width:110px">每手最大亏损额/占用保证金</th>
            <th style="min-width:60px">风险暴露得分</th>
            <th style="min-width:60px">平均交易周期</th>
            <th style="min-width:100px">每手平均盈亏/交易单位</th>
            <th style="min-width:47px">敏感度得分</th>
            <th style="min-width:60px">交易笔数</th>
            <th style="min-width:65px">综合得分</th>
            
        </thead>
        <tbody v-if="monlist.length>0">
            <tr v-for="(item,index) in monlist" :key="index" style="border:none;">
                <td>{{item.mon}}</td>
                <td>{{item.DFRANK}}</td>
                <td>{{item.YKJE}}</td>
                <td>{{item.szqy}}</td>
                <td>{{item.JZ}}</td>
                <td>{{item.YLNLDF}}</td>
                <td>{{parseInt(item.SL * 100)}}%</td>
                <td>{{item.YKB == '999999' ? '999...' : item.YKB}}</td>
                <td>{{item.WDXDF}}</td>
                <td>{{item.MAXQYHCBL}}</td>
                <td>{{item.MAXKSBL}}</td>
                <td>{{item.FXBLDF}}</td>
                <td>{{item.PJJYTS}}</td>
                <td>{{item.MSPJYL}}</td>
                <td>{{item.MGDDF}}</td>
                <td>{{item.jycs}}</td>
                <td>{{item.DFVAL}}</td>
                
            </tr>
        </tbody> -->
        <tbody v-else>
            <tr style="border:none">
                <td v-for="(i,index) in 19" :key="index">--</td>
                <!-- <td colspan="15" class="noData">暂无数据</td> -->
            </tr>
        </tbody>
    </table>
</div>
</template>
<script>
export default {
    props:{
        monlist: Array
    }
}
</script>
<style scoped src="./style.css"></style>
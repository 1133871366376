<template>
    <div class="modify">
        <el-dialog class="dialog" title="完善领奖信息"  width="546px" top='30vh' :visible.sync="nahua" @close="close">
            <div class="line"></div>
            <div v-loading="loading">
                <div class="modifyName nahuaname" style="">
                    <div style="position: relative;margin-bottom:16px;">
                        <label class="nahualeft">姓名</label>：{{name}}
                    </div>
                    <div style="position: relative;margin-bottom: 30px;">
                        <label class="nahualeft">南华期货开户的资金账号</label>：
                        <el-input v-model="inputnahua"></el-input>
                        <div class="errortips" style="right: -1px;bottom: 0px;left: inherit;">*奖金会打入该账户，开户人须<br> 与参赛实名认证的人名保持一致</div>
                    </div>
                    <div style="position: relative;">
                        <label class="nahualeft">联系电话 </label>：
                        <el-input v-model="inputtel"></el-input>
                        <div class="errortips" style="right: 137px;bottom: 8px;left: inherit;">*选填</div>
                    </div>
                </div>
                <div class="tips">
                    <div>
                        <div>注：</div>
                        <div>
                            <div class="tipslist tipsflex"><div style="margin-top: 2px;">1.</div><div>请在截止日{{pricedata && pricedata.ENDRQ}}前提交以上信息，否则视为放弃领取奖金；</div></div>
                            <div class="tipslist">2.奖金发放时间：获奖次月的最后一天；</div>
                            <div class="tipslist tipsflex"><div style="margin-top: 2px;">3.</div><div>截止日当天，我们会对模拟豆数量进行审核，请确保你的模拟豆数量不能为负值，否则取消获奖资格；</div></div>
                            <div class="tipslist">4.如有疑问请拨打文华客服电话：4008113366。</div>
                        </div>
                    </div>
                    <div style="text-align:center;"><span class="btn" @click="updatename">提交</span></div>
                </div>
            </div>
            
        </el-dialog>
    </div>
</template>
<script>
</script>
<script>
import { PrizeUserUpdate,WalletApi } from "../../../assets/js/request";
export default {
    name:"modify",
    data(){
        return{
            name:'',
            inputnahua:'',
            inputtel:'',
            nahua:false,
            loading:false,
            messagenahua:''
        }
    },  
    props:{
      nahuashow:{
        type:Boolean,
        default:false
      },
      pricedata:{
        type:Object
      },
      whid:String
    },
    watch:{
        nahuashow(val){
            this.nahua = val
        },
        pricedata:{
            handler(val){
                console.log(val)
                this.name = val && val.ZHXM
                if(!this.name){
                    this.init()
                }
                this.inputnahua = val && val.JYZH
                this.inputtel = val && val.PHONE.trim()
            },
            deep:true
        }
    },
    methods: {
        // 实名认证信息
        async init(){
            this.loading = true
            let data=  await WalletApi({whid :this.whid })
            this.name = data.realname
            this.loading = false
        },

        //判断位数
        strLen(str){
            var realLength = 0,
                charCode = -1
            for (var i = 0; i < str.length; i++) {
                charCode = str.charCodeAt(i)
                if (charCode > 0 && charCode <= 128) {
                    realLength += 1
                } else {
                    realLength += 2
                }
            }
            return realLength
        },
        // 修改昵称
        async updatename(){
            let vm = this
            if(!this.inputnahua){
               this.$message({
                    message: '请输入南华期货交易账号',
                    type: 'warning'
                });
               return false
            }
            var reg = /^\d{8}$/;
            if(!reg.test(this.inputnahua.trim())){
                this.$message({
                    message: '输入的南华期货交易账号格式有误',
                    type: 'warning'
                });
               return false
            }
            if(this.inputtel.trim()){
                var regtel = /^[1][3,4,5,6.7,8,9][0-9]{9}$/
                if(!regtel.test(this.inputtel.trim())){
                    this.$message({
                        message: '请输入正确格式手机号',
                        type: 'warning'
                    });
                    return false
                }
            }
            this.loading = true
            let data= await PrizeUserUpdate({whid:this.whid,account:this.inputnahua.trim(),phonenum:this.inputtel.trim(),phonenum_login: localStorage.getItem('phone')});
            this.loading = false
            if(data.status){
                // this.close()
                this.$message({
                    message: '成功',
                    type: 'success',
                    onClose:()=>{
                        this.close()
                    }
                });
            }else{
               this.$message.error(data.message);
            }
        },
        close(){
            this.$emit("update:nahuashow",false)
        }
    }
}
</script>
<style scoped src="./style.css"></style>
<template>
    <div class="modify">
        <el-dialog class="dialog" title="修改昵称"  width="546px" top='30vh' :visible.sync="modify" @close="close">
            <div class="line"></div>
            <div class="modifyName" v-loading="loading">
                <div style="position: relative;">
                    <label class="left">请输入新昵称：</label>
                    <el-input v-model="input"></el-input>
                    <div class="error">{{message}}</div>
                </div>
                <div style="text-align:center;"><span class="btn" @click="updatename">修改</span></div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
</script>
<script>
import { UpdateNickName } from "../../../assets/js/request";
export default {
    name:"modify",
    data(){
        return{
            input:'',
            modify:false,
            loading:false,
            message:'' //错误提示
        }
    },  
    props:{
      modifyshow:{
        type:Boolean,
        default:false
      },
      ischangename:{
        type:Boolean,
        default:false
      },
      whid:String
    },
    watch:{
        modifyshow(val){
            this.modify = val
        },
        input(val){
            this.message = ''
        }
    },
    methods: {
        //判断位数
        strLen(str){
            var realLength = 0,
                charCode = -1
            for (var i = 0; i < str.length; i++) {
                charCode = str.charCodeAt(i)
                if (charCode > 0 && charCode <= 128) {
                    realLength += 1
                } else {
                    realLength += 2
                }
            }
            return realLength
        },
        // 修改昵称
        async updatename(){
            let vm = this
            var reg = /^[\u4E00-\u9FA5A-Za-z0-9]+$/;
            if(!this.input){
               this.message = '请输入昵称'
               return false
            }
            if(vm.input.trim()==''){
                vm.message = '新昵称不能为空';
                return
            }else if(vm.strLen(vm.input.trim())>10){
                vm.message = '新昵称不能超过10个字符';
                return
            }else if(!reg.test(vm.input.trim())){
                vm.message = '昵称仅支持中文、英文或数字';
                return
            }
            this.loading = true
            let phone = localStorage.getItem('phone')
            let data= await UpdateNickName({whid:this.whid,nickname:this.input,phonenum: phone});
            this.loading = false
            if(data.status){
                this.close()
                this.$emit("update:ischangename",true)
            }else{
                this.message = data.message
            }
        },
        close(){
            this.$emit("update:modifyshow",false)
        }
    }
}
</script>
<style scoped src="./style.css"></style>
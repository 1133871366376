import {GetIsSuccess } from "../../assets/js/request";
export default {
    data: () => ({
        success: false,
        message: '',
        loading: false,
        orderid: ''
    }),
    created() {
        this.init()
    },
    methods: {
        async init(){
            this.orderid = this.$route.query.out_trade_no
            this.loading = true
            let data = await GetIsSuccess({ out_trade_no: this.orderid })
            this.loading = false
            if (data.status){
                this.success = true
                this.message = data.message
            }else{
                this.success = false
            }
        },
        back(){
            this.$router.push({
                path: "/personalCenter",
            });
        }
        
    }
}
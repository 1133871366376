<template>
    <div class="login">
            <div class="loginpanel">
                <div class="loginimg"></div>
                <div class="loginlist">
                    <label>云账号</label>
                    <el-input
                        class="userInput"
                        placeholder="请输入云账号"
                        v-model="whid"
                        clearable>
                    </el-input>
                </div>
                <div class="loginlist">
                    <label>密码</label>
                    <el-input
                        class="userInput"
                        placeholder="请输入密码"
                        show-password
                        v-model="pwd"
                        clearable>
                    </el-input>
                    <a href="https://cloudid.wenhua.com.cn/Home/WenhuaIDForgot" target="_blank" class="link forget">忘记密码</a>
                </div>
                <div style="margin-top:36px;">
                    <div class="l_btn" @click="loginbtn">登录
                        <!-- <a @click.stop href="https://cloudid.wenhua.com.cn/Home/WenhuaIDRegistor" target="_blank" class="link register">注册</a> -->
                    </div>
                </div>
                <div style="margin-top:29px;font-size:14x;">
                    没有账号？<a @click.stop href="https://cloudid.wenhua.com.cn/Home/WenhuaIDRegistor" target="_blank" class="link" style="font-size: 14px;">注册文华云账号做模拟交易</a>
                </div>

                <div class="simlogin" v-if="false">
                    <div class="dash"></div>
                    <div><span class="lineblue" :class="{lineblueshow:showsim}" @click="showsimbox">模拟交易账号绑定手机号</span></div>
                    <div class="simcontent" :style="{'height':simheight}">
                        <div class="simcheck" ref="boxheight" >
                            <div v-if="!showbind" >
                                <div class="flex">
                                    <div class="simtext">模拟交易账号</div>
                                    <div>
                                        <el-input
                                            class="simInput"
                                            placeholder="请输入模拟交易账号"
                                            v-model="simid"
                                            clearable>
                                        </el-input>
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="simtext">密码</div>
                                    <div>
                                        <el-input
                                            class="simInput"
                                            placeholder="请输入密码"
                                            show-password
                                            v-model="simpwd"
                                            clearable>
                                        </el-input>
                                    </div>
                                </div>
                                <div class="simbtn" @click="simcheck">提&nbsp;交</div>
                                <div class="simmess">{{message}}</div>
                            </div>
                            <!-- v-show="showbind" -->
                            <div v-else>
                                <div style="margin:5px 0 15px;font-size:13px;color:#5D5D5D;">依据<a href="http://www.cac.gov.cn/2016-11/07/c_1119867116.htm" class="link" target="_blank">《网络安全法》</a>相关要求，自2017年6月1日起，使用互联网服务需进行帐号实名认证，为了不影响功能的正常使用，请您尽快指定手机号</div>
                                <div style="margin:0 0 -5px 0;font-size:13px;color:#5D5D5D;">内盘模拟交易账号：{{simid}}</div>
                                <div class="flex">
                                    <div class="simtext" style="width:45px;">手机号</div>
                                    <div style="flex-grow: 1;">
                                        <el-input
                                            class="simInput"
                                            placeholder="请输入要绑定的手机号"
                                            v-model="simtel"
                                            clearable>
                                        </el-input>
                                    </div>
                                </div>
                                <verfity :phone="simtel" :simcode.sync="simcode" :mess.sync="message" :reset.sync="reset"></verfity> 
    <!-- 
                                <div class="flex">
                                    <div class="simtext" style="width:45px;flex-shrink:0;">验证码</div>
                                    <div style="flex-grow: 1;">
                                        <el-input
                                            class="simInput"
                                            placeholder="请输入验证码"
                                            v-model="simcode"
                                            clearable>
                                        </el-input>
                                    </div>
                                    <div class="simcode">获取</div>
                                </div> -->
                                <div class="simmess" style="margin-left:0">{{message}}</div>
                                <div class="simbtn" style="margin-left: 52px;" @click="simbind" v-if="!showrebind && !successbind">绑定手机号</div>
                                <div v-else>
                                    <div v-if="!successbind">
                                        <div class="simbtn" style="margin-left: 52px;" @click="cancelbind">取消</div>
                                        <div class="simbtn" style="margin-left: 52px;" @click="rebind">确认指定</div>
                                    </div>
                                </div>

                                <div style="margin:15px 0 0;font-size:13px;color:#5D5D5D;">
                                    <div>提示：</div>
                                    <div>1、指定手机号以后，模拟交易账号只能使用手机号进行登录</div>
                                    <div>2、如果该手机号未注册文华云，指定后自动注册文华云账号，登录密码为交易账号密码</div>
                                    <div>3、如果该手机号已注册文华云，此操作将替换该云账号原绑定的交易账号</div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>
        
    </div>
</template>
<script>
import { Login,GetSimPhoneBindCheck,SimulationCheckCode,SimulationMnBindingPhone} from "../../../assets/js/request";
import verfity from "../components/verfity.vue"
export default {
    data() {
        return {
            whid:'',
            pwd:'',
            simid:'', //模拟交易账号
            simpwd:'', //模拟交易密码
            showsim:false, //是否显示模拟交易账号输入框验证区域
            simheight:'0px',  //输入验证框高度（即是否隐藏）
            showbind:false, //是否显示绑定账号页面
            message:'', //是否显示错误信息
            simtel:'',
            simcode:'',
            showrebind:false, //是否重新绑定手机号按钮回显
            reset:false, //是否重置验证码和人机验证
            successbind:false //是否绑定成功
        }
    },
    components:{
        verfity
    },

    watch:{
        showsim(val){
            if(val){
                this.simheight = this.$refs.boxheight.offsetHeight + 50 +'px'
            }else{
                this.simheight = '0px'
            }
        },
        showbind(val){
            if(val){
                this.simheight = this.$refs.boxheight.offsetHeight + 260 +'px'
            }
        },
        simid(){
            this.message = ''
        },
        simpwd(){
            this.message = ''
        },
        simtel(){
            if(!this.reset){
                this.message = ''
            }
        },
        simcode(){
            this.message = ''
        }
    },
    methods: {
        async loginbtn(){
            let vm = this;
            vm.$emit("loading",true);
            let param = {
                Username: vm.whid,
                Password: vm.pwd
            }
            let data = await Login(param);
            console.log(data.data)
            vm.$emit("loading",false);
            if(data.status){
                //没有实名认证
                localStorage.setItem("token",data.token)
                localStorage.setItem("whid", data.data.whid)
                localStorage.setItem("phone",data.data.phone)
                if (data.data.confirm == 0){
                    // localStorage.setItem("token", '')
                    // localStorage.setItem("whid", '')
                    localStorage.setItem("confirm", 0)
                    // vm.$emit('showDialog','实名认证','confirm')
                    // return false
                }else{
                    localStorage.setItem("confirm", 1)
                }
                // localStorage.setItem("token",data.token)
                // localStorage.setItem("whid", data.data.whid)
              
                location.reload(); //必须刷新页面，否则首页无法获取登录信息
           
            }else{
                // localStorage.setItem("confirm", 0)
                vm.$alert(data.message, '提示', {
                    confirmButtonText: '确定',
                    showClose:false,
                    callback: action => {
                      
                    }
                });
            }
        },
        async simcheck(){
            // Zhlb（string型）账号 类别   --0:模拟 1:外盘 2:股票 3:ETF
            let data = await GetSimPhoneBindCheck({Jyzh:this.simid,Pwd:this.simpwd,Zhlb:0})
            if(this.simid == ''){
                this.message = '请输入模拟交易账号'
                return
            }
            if(this.simpwd == ''){
                this.message = '请输入密码'
                return
            }
            if(data.status){
                this.showbind = true
            }else{  
                this.message = data.message
            }
        },
        showsimbox(){
            this.showsim = !this.showsim
            // this.showbind = false
        },
        async simbind(){
            if(this.simtel == ''){
                this.message = '请输入手机号'
                return
            }
            if(this.simcode == ''){
                this.message = '请输入验证码'
                return
            }
            let data = await SimulationCheckCode({Jyzh:this.simid,Pwd:this.simpwd,Phone:this.simtel,Code:this.simcode})
            if(data.status){
                // this.message = data.message
                if(data.code == 'exists_phone'){
                    this.showrebind = true
                    this.message = '目标手机号已注册文华云，确认后将替换该云账号绑定的原交易账号，原交易账号将不可使用'
                }else{
                    this.rebind()
                }
            }else{
                this.message = data.message
            }
        },
        cancelbind(){
            this.message = ''
            this.showrebind = false
            this.simtel =  ''
            this.reset = true
        },
        async rebind(){
            // Zhlb（string型）账号 类别   --0:模拟 1:外盘 2:股票 3:ETF
            let data = await SimulationMnBindingPhone({Jyzh:this.simid,Pwd:this.simpwd,Phone:this.simtel,Zhlb:0})
            console.log(data)
            if(data.status){
                this.message = data.message
                this.successbind = true
            }else{
                this.message = data.message
                this.showrebind = false
                this.simtel =  ''
                this.reset = true
            }
        }
    }
}
</script>
<style scoped src="./style.css"></style>
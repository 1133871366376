<!-- 公告栏组件 -->
<template>
 <div class="lunbo_txt">
	<ul class="scrollUl" ref="scrollUl" :style="{left:num + 'px'}" @mouseenter="mouseenter"  @mouseleave="mouseleave">
		<li>
			<!-- <span>{{text}}</span> -->
            <router-link class="span" :to="{path:'/description',query:{id:'three'}}" target="_blank"> {{text}}</router-link>
            <!-- <router-link class="link" :to="{path:'/description',query:{id:'three'}}" target="_blank"> 查看详细规则</router-link> -->
		</li>
		<li>
			<!-- <span>{{text}}</span> -->
            <router-link class="span" :to="{path:'/description',query:{id:'three'}}" target="_blank"> {{text}}</router-link>
            <!-- <router-link class="link" :to="{path:'/description',query:{id:'three'}}" target="_blank"> 查看详细规则</router-link> -->
		</li>
	</ul>
</div>
</template>

<script>
let timer = null
export default {
    name: 'NoticeBar',
    data: () => ({
        num : 100
    }),
    props: {
        text: {
            type: String,
            default: ''
        }
    },
    mounted () {
        //设置滚动速度
	    timer = setInterval(()=>{
            this.goleft()
        },20)
    },
    methods:{
        goleft(){
            //750是根据你给的尺寸，可变的
            if (this.num == -900) {
                this.num = 0;
            }
            this.num -= 1;
        },
        mouseenter(){
            clearInterval(timer);
        },
        mouseleave(){
            timer = setInterval(()=>{
                this.goleft()
            },20)
        }
    }

}
</script>
<style scoped>
    .lunbo_txt{
        position: relative;
        width: 848px;
        height: 30px;
        margin: 0 auto;
        padding: 0px 0 10px 0;
        line-height: 30px;
        overflow: hidden;
    }

    .lunbo_txt .link {
        color: #367ff5;
        text-decoration: underline;
        font-size: 12px;
    }

    .scrollUl {
        position: absolute;
        height: 30px;
        left: 0;
        top: 0px;
        margin: 0;
        width: 1800px;
    }

    .scrollUl li {
        list-style: none;
        float: left;
        width: 900px;
    }

    .scrollUl li .span {
        cursor: pointer;
        text-decoration: none;
        font-size: 14px;
        color: #6c7a7a;
    }

   

</style>


<template>
<div>
    <!-- <div class="rankTitle">“比赛日”产出比</div> -->
    <table class="rank" v-if="showmore" cellspacing="0" cellpadding="0">
        <thead>
            <th style="width:13%">日期</th>
            <th>排名</th>
            <th>日内产出比</th>
            <th>平今笔数</th>
            <th>平今收益</th>
            <th>日内手续费</th>
            <th>当日权益</th>
            <th>当日净值</th>
        </thead>
    </table>
    <table class="rank" v-else cellspacing="0" cellpadding="0">
        <thead>
            <th>日期</th>
            <th>排名</th>
            <th>日内产出比</th>
            <th>平今笔数</th>
            <th>平今收益</th>
            <th>日内手续费</th>
            <th>当日权益</th>
            <th>当日净值</th>
        </thead>
        <tbody v-if="ccblist.length>0">
            <tr v-for="(item,index) in ccblist" :key="index" style="border:none;">
                <td>{{item.BCRQ}}</td>
                <td>{{item.rankid == "0" ? "--" : item.rankid}}</td>
                <td>{{item.ccb}}</td>
                <td :class="{red:item.rankid == '0' }">{{item.jycs}}</td>
                <td>{{item.pcyk}}</td>
                <td>{{item.sxf}}</td>
                <td>{{item.szqy}}</td>
                <td>{{item.drjz}}</td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr style="border:none">
                <td v-for="(i,index) in 8" :key="index">--</td>
                <!-- <td colspan="8" class="noData">暂无数据</td> -->
            </tr>
        </tbody>
    </table>
</div>
</template>
<script>
export default {
    props:{
        ccblist: Array,
        showmore:{
            type:Boolean,
            default:false
        }
    }
}
</script>
<style scoped src="./style.css"></style>
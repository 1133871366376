<template>
    <div>
        <table v-loading='loading'>
            <tr>
                <th>品种</th>
                <th>代码</th>
                <th>保证金率</th>
                <th>手续费（每手）</th>
                <th>手续费（比例）</th>
                <th>平今手续费（每手）</th>
                <th> 平今手续费（比例）</th>
            </tr>
            <tr v-for="(item,index) in tableList" :key="index">
                <td v-if="index < 6 || show">{{item.pzmc}}</td>
                <td v-if="index < 6 || show">{{item.pzdm}}</td>
                <td v-if="index < 6 || show">{{item.bzbail}}</td>
                <td v-if="index < 6 || show">{{item.bzsxf}}</td>
                <td v-if="index < 6 || show">{{item.sxfl}}</td>
                <td v-if="index < 6 || show">{{item.PJSXF_V}}</td>
                <td v-if="index < 6 || show">{{item.PJSXF_R}}</td>
            </tr>
        </table>
        <div class="more" @click="more" v-show="tableList.length > 6">{{show?'收起':'显示更多'}}</div>
    </div>
</template>
<script>
export default {
    data:()=>({
        show:false,
    }),
    props:{
        tableList:Array,
        loading:Boolean
    },
    watch:{
        tableList(){
            this.show = false
        }
    },
    methods:{
        more(){
            this.show = !this.show
        }
    }
}
</script>
<style scoped>
table {
    width: 100%;
    font-size: 12px;
    text-align: center;
    table-layout:fixed;
    border-spacing: 1px 0;
}
th {
    background: #e0c499;
    color: #fff;
    font-weight: normal;
    height: 30px;
    line-height: 30px;
    text-align: center;
}
td {
    color: #51555c;
    height: 38px;
    line-height: 38px;
}
tr:nth-of-type(2n){
    background: #FAFCFF;
}
tr:nth-of-type(2n+1){
    background: #F0F6FF;
}
.more{
    text-align: center;
    color: #367FF5;
    cursor: pointer;
    user-select: none;
    font-size: 12px;
    padding: 10px 0 0;
}
</style>

<template>
    <div class="description">
        <div>文华模拟交易平台作为国内主要的期货交易投资者在线教育课堂，有20万活跃用户在此通过模拟交易，学习期货交易知识，培训自己的交易能力。其中有在期货市场沉浸多年的老期货在此锤炼自己的新策略，有期货专业的大学生，也有初入期货市场的小白……各路英豪、追梦人齐聚在此不断试练，优化自己的交易思路，学习、了解各期货品种的特性……</div>
        <div style="margin-top: 7px;">为鼓励这群喜学习、爱探索、不断求知，不断超越自我有追求的期货人，文华特举办“比赛日”活动。</div>
        <div style="margin-top: 7px;">本活动为顾全不同交易特点的参与者，为日内交易者设置“比赛日”奖，将每周三定为比赛日，排名指标每周二公布，取前20名将获得比赛日奖金。也为中长线参与者设置“月冠军”奖，每月开奖，前5名获得者为月冠军。</div>
        <div style="margin-top: 7px;">最后，预祝各位在此平台上都能够试练、摸索出具有自己特点的交易之路，能够发现属于自己的金矿，并祝各位在学习、探索的路上快乐前行！</div>

        <div class="d_title">一、活动时间</div>
        <div>2022年9月1日-2024年1月31日，以每个自然月为一个赛季。</div>

        

        <div class="d_title" id="two">二、参与条件</div>
        <div>本活动不设报名参赛环节，凡充值模拟豆后，消耗模拟豆做交易时该登录的模拟交易账号即自动作为参赛选手。</div>
        <div>但是，账户存在以下情况的除外，不能纳入比赛日活动的选手范围：</div>
        <div>开赛时剩余模拟豆总数 > 历史累积购买的模拟豆总数。如果后续模拟豆消耗至不再满足上述条件，并再次充值购买模拟豆时会被自动加入本活动。</div>
        <!-- <div>（1）开赛时剩余模拟豆总数&lt;历史累积购买的模拟豆总数自动参与活动；</div>
        <div>（2）开赛时剩余模拟豆不满足上面（1）的条件，直到模拟豆消耗至满足上条（1）参赛条件后，并再次充值模拟豆时会被自动加入本活动。</div> -->



        <div class="d_title">三、参与软件</div>
        <div>
            <a href="https://app.wenhua.com.cn/download.html" target="_blank" style="color: #367ff5;text-decoration: none;">随身行APP</a>（主要软件）、以及PC版的
            <a href="https://wh6.wenhua.com.cn/Download/index?flag=1" target="_blank" style="color: #367ff5;text-decoration: none;">wh6</a>（主要软件）、
            <a href="https://rich.wenhua.com.cn/#/download" target="_blank" style="color: #367ff5;text-decoration: none;">wh7</a>、
            <a href="https://wt8.wenhua.com.cn/#/download/download/0" target="_blank" style="color: #367ff5;text-decoration: none;">WT8</a>、
            <a href="https://wt9.wenhua.com.cn/Download/index" target="_blank" style="color: #367ff5;text-decoration: none;">WT9</a>。
        </div>
        <div>参赛的模拟交易账号登录上述任意软件下单成交，都会被计入比赛成绩，需特别注意的是参赛模拟交易账号的成交单都会消耗模拟豆。</div>




        <div class="d_title">四、活动规则</div>
        <div>1.交易品种：国内5家期货交易所所有期货、期权品种；</div>
        <div>2.为了保证活动的公平性，模拟合约的手续费、保证金按统一标准收取，模拟交易的每次最大开仓手数及合约最大仓位限制与交易所规定保持一致；</div>
        <div>3.同一MAC只允许唯一的模拟交易账号参与比赛，否则将被取消获奖资格；</div>
        <div>4.违规参与者、作弊参与者一经发现，文华有权立即清除其所有大赛成绩，并取消其参赛与获奖资格；</div>
        <div>5.赛程中文华有权本着维护大多数参赛者权益的原则随时修改、完善本活动规则。</div>




        <div class="d_title"  id="three">五、奖项奖金</div>
        <div class="flex">
            <!-- <div class="ygjj award"></div> -->
            <table style="width: 790px;">
                <tr>
                    <th style="width:110px;">奖项</th>
                    <th style="width:220px;">排名指标</th>
                    <th>获奖名次</th>
                    <th style="width:120px;">奖金</th>
                    <th style="width:230px;">获奖条件</th>
                </tr>
                <tr>
                    <td>比赛日</td>
                    <td>当日盈亏比、当日胜率、当日产出比三项指标随机周二公布选其一</td>
                    <!-- <td>当日盈亏比、当日胜率两项指标随机周二公布选其一</td> -->
                    <td>前20名</td>
                    <td>500元/人</td>
                    <td style="text-align:justify;">平今仓笔数>20笔</td>
                </tr>
                <tr>
                    <td>月冠军</td>
                    <td>当月综合得分</td>
                    <td>前5名</td>
                    <td>1000元/人</td>
                    <td style="text-align:justify;">当月平仓>4笔<br>当月持仓天数>14个交易日<div style="margin-top: -4px;margin-left: -9px;">（当天开仓后平仓不算持仓）</div></td>
                </tr>
            </table>
        </div>
        <div style="margin-top:5px;">
            <div style="font-size:13px;text-indent: 0em;">*以上指标当排名出现并列情况，则交易笔数多的排名在前；如交易笔数仍相同，则盈利额多的排名在前。</div>
        </div>

        <div class="jxsf">排名指标算法 <a class="updown" @click="updown">{{showsim?'收起':'展开'}}</a></div>
        <div :style="{'height':simheight}" class="simcontent">
            <table class="blue allblue" ref="boxheight" style="width:100%;">
                <tr>
                    <th style="width:150px;">指标</th>
                    <th style="width:290px;">数据范围</th>
                    <th>计算方式</th>
                    <th>评比指标说明</th>
                </tr>
              
            
                <tr>
                    <td>胜率“比赛日”</td>
                    <!-- rowspan="3" -->
                    <td  rowspan="2" class="white">
                        比赛当日<br>
                        在比赛日当天开的仓都记入比赛成绩，当天平掉的仓以平仓价计算成绩，当天未平掉的仓以合约当日收盘价计算成绩。
                        <!-- <div class="line"></div> -->
                    </td>
                    <td>
                        <div class="gs03"></div>
                    </td>
                    <td>根据一个交易日内的盈利+持平的交易次数比率作为评比标准，胜率高胜出。</td>
                </tr>
                <tr>
                    <td>盈亏比“比赛日”</td>
                    <!-- <td>
                        比赛当日<br>
                        比赛成绩须是当天交易成绩，即在比赛日当天开仓后，在当天平掉的才被计入比赛成绩。
                    </td> -->
                    <td>
                        <div class="gs07"></div>
                    </td>
                    <td>
                        根据一个交易日内的平均盈亏比率做为评比标准，盈亏比高者胜出。
                    </td>
                </tr>
                <tr>
                    <td>产出比“比赛日”</td>
                    <!-- <td>
                        比赛当日<br>
                        比赛成绩须是当天交易成绩，即在比赛日当天开仓后，在当天平掉的才被计入比赛成绩。
                    </td> -->
                    <td>
                        比赛当日<br>
                        在比赛日当天开仓后且在当天平掉的被计入比赛成绩。
                        <!-- <div class="line"></div> -->
                    </td>
                    <td>
                        <div class="gs08"></div>
                    </td>
                    <td>
                        根据一个交易日内的收益和成本比率作为评比标准，产出比值高者胜出
                    </td>
                </tr>
                  <tr>
                    <td>月冠军综合得分</td>
                    <td class="border">比赛当月</td>
                    <td>
                        盈利能力得分+风险控制得分+稳定性得分+胜率得分+市场感知得分
                        <el-popover
                            class="link"
                            placement="bottom"
                            width="680"
                            trigger="hover"
                            >
                            <el-button slot="reference" class="etext">查看详情</el-button>
                            <div name="reference" class="referbox">
                                <div>
                                    <span class="fwtitle">盈利能力得分:</span>
                                    按净值收益率排名，净值收益率最大得20分，每相差一名，得分相差0.01
                                </div>
                                <div style="margin-left:85px;">
                                    <div>净值收益率=（P1*P2*P3.....*Pn）-1，Pn为当日净值。</div>
                                    <div>Pn的计算按（当日盈亏-当日手续费）的值分三种情况：</div>
                                    <div class="mt1" style="margin-bottom:8px;"><span class="fwtitle">情况1：</span>（当日盈亏-当日手续费）>0，出金按盘后，入金按盘前；</div>
                                    <div class="gs1"></div>
                                    <div class="mt1"><span class="fwtitle">情况2：</span>（当日盈亏-当日手续费）=0；</div>
                                    <div style="margin-top:3px;">当日净值=1。</div>
                                    <div class="mt1" style="margin-bottom:4px;"><span class="fwtitle">情况3：</span>（当日盈亏-当日手续费）&lt;0，出金按盘后，入金按盘后；</div>
                                    <div class="gs2"></div>
                                    <div>注：权益为市值权益</div>
                                </div>
                                <div class="mt"><span class="fwtitle">风险控制得分：</span><span class="gs3"></span><span class="md">，其中</span><span class="gs4"></span></div>
                                <!-- <div class="gs4"></div> -->
                                <div class="mt"><span class="fwtitle">稳定性得分：</span><span class="gs5"></span><span>，其中总盈亏=当日期末权益-当日期初权益-出入金</span></div>
                                <!-- <div style="margin:4px 0 -2px;">总盈亏=每日盈亏之和，当日总盈亏&lt;上日总盈亏时，总盈亏回撤天数加1；</div> -->
                                <div class="mt"><span class="fwtitle">胜率得分:</span><span class="gs6"></span></div>
                                <div class="mt"><span class="fwtitle">市场感知得分：</span><span class="gs7"></span><span class="md">，其中</span><span class="gs8"></span></div>
                                <!-- <div class="gs8"></div> -->
                                <div>注：1.参赛前的老仓以上一日结算价作为持仓均价计算盈亏及权益。</div>
                                <div style="margin-left:28px;">2.当月最后一个交易日未平仓合约以当日收盘价计算盈亏及权益。</div>
                                <div style="margin-left:28px;">3.如当日净值&lt;0，则按退赛重新参赛处理。</div>
                            </div>
                        </el-popover>
                    
                    </td>
                    <td>自不同围度根据科学的算法评分，自检参交易水平，得分高者胜出。</td>
                </tr>
            
            </table>
        </div>
        

        
        <div class="d_title">六、领奖说明</div>
        <div>1、本活动，每个奖项每人只能获奖一次。若出现重复获奖者则该获奖者将被忽略不计，后面名次顺次提前；</div>
        <div>2、奖金由赞助商南华期货提供，按南华期货公司规定发放奖金，请获奖者在赛季结束后10个自然日内准备好本人（注册模拟交易账号的人）的南华期货的交易账户（奖金将打入该账户中）并成交一笔，到期不具备领取奖金条件者视为其放弃奖金；</div>
        <div>3、模拟豆剩余数量不能为负数，才有领奖资格，如领奖超期即为放弃领奖；</div>
        <div>4、颁发的所有奖金均为含税金额，个人所得税自理，由南华期货代扣代缴。</div>

        <div class="d_title">七、免责声明</div>
        <div style="margin-bottom: 25px;">本次活动披露的收益率、排名等相关信息仅限本活动范围参考，不构成外部投资建议，对此产生的影响，文华财经不负任何责任；文华财经拥有对本活动的最终解释权。</div>
    </div>
</template>
<style scoped src="./style.css"></style>
<script>
export default {
    data:()=>({
        query:'',
        showsim:false, //是否显示表格
        simheight:'0px'  //表格高度（即是否隐藏）
    }) ,
    watch:{
        showsim(val){
            if(val){
                this.simheight = this.$refs.boxheight.offsetHeight +'px'
            }else{
                this.simheight = '0px'
            }
        },
    },
    created(){
        this.$nextTick(()=>{
            this.query = this.$route.query ? this.$route.query.id : ''
            if(this.query){
                if(this.query == "two"){
                    let two = document.getElementById("two")
                    if(!!two){
                        two.scrollIntoView(true)
                    }
                }
                if(this.query == "three"){
                    let three = document.getElementById("three")
                    if(!!three){
                        three.scrollIntoView(true)
                    }
                }
               
            }
        })
    },
    methods:{
        updown(){
            this.showsim = !this.showsim
        }
    }
}
</script>
<template>
<div>
    <!-- <div class="rankTitle">“比赛日”胜率</div> -->
    <table class="rank" v-if="showmore"  cellspacing="0" cellpadding="0">
        <thead>
            <th style="width:11%">日期</th>
            <th>排名</th>
            <th>日内胜率</th>
            <th>平今笔数</th>
            <th>平今收益</th>

            <!-- <th>净利润</th> -->

            <!-- <th>当日期初权益</th> -->
            <!-- <th>当日期末权益</th>
            <th>当日净值</th> -->

            <th>盈利笔数</th>
            <th>亏损笔数</th>
            <th>持平笔数</th>

            <th>当日权益</th>
            <th>当日净值</th>
        </thead>
    </table>
    <table class="rank" v-else cellspacing="0" cellpadding="0">
        <thead>
            <th>日期</th>
            <th>排名</th>
            <th>日内胜率</th>
            <th>平今笔数</th>
            <th>平今收益</th>

            <!-- <th>净利润</th> -->

            <!-- <th>当日期初权益</th> -->
            <!-- <th>当日期末权益</th>
            <th>当日净值</th> -->

            <th>盈利笔数</th>
            <th>亏损笔数</th>
            <th>持平笔数</th>

            <th>当日权益</th>
            <th>当日净值</th>
        </thead>
        <tbody v-if="sllist.length>0">
            <tr v-for="(item,index) in sllist" :key="index" style="border:none;">
                <td>{{item.BCRQ}}</td>
                <td>{{item.RANKID == "0" ? "--" : item.RANKID}}</td>
                <td>{{parseInt(item.SL * 100)}}%</td>
                <td :class="{red:item.RANKID == '0' }">{{item.JYCS}}</td>
                <td>{{item.pcyk}}</td>

                <!-- <td>{{item.DRYL}}</td> -->

                <!-- <td>{{item.zrqy}}</td>
                <td>{{item.szqy}}</td>
                <td>{{item.DRJZ}}</td> -->

                <td>{{item.YLCS}}</td>
                <td>{{item.KSCS}}</td>
                <td>{{item.CPCS}}</td>

                <td>{{item.szqy}}</td>
                <td>{{item.DRJZ}}</td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr style="border:none">
                <td v-for="(i,index) in 10" :key="index">--</td>
                <!-- <td colspan="8" class="noData">暂无数据</td> -->
            </tr>
        </tbody>
    </table>
</div>
</template>
<script>
export default {
    props:{
        sllist: Array,
        showmore:{
            type:Boolean,
            default:false
        }
    }
}
</script>
<style scoped src="./style.css"></style>
import Table from '@/components/Table.vue'
import performance from './components/performance'
import accountRating from './components/accountRating'
import scrollText from './components/scrollText'
import blueTab from '../../components/blueTab.vue'
import { Performance, GetRankList, ScrollListGet, GetDayRankList, GetDayPrizeList, GetMonthPrizeList,GetMonthList } from "../../assets/js/request";
export default {
    data: () => ({
        tabActive: '-1',
        realTabActive:'1', //真正的选中的tab，目的是tab切换时，使数据加载完毕再切换tab
        tabList: [
            {
                name: '比赛日',
                value: '1'
            },
            {
                name: '月冠军',
                value: '0'
            }
        ],
        tableData: [],
        loading: false,
        p_loading: false, //个人成绩报告的loading
        userdata: null,
        year: '',
        // month: '',
        whid: '',
        scrtext:'比赛日活动已结束，感谢各位投资者的参与！',//大赛公告数据
        daytype: '', //比赛日指定指标字段 1 - 最大回撤比 2 - 盈亏比 3 - 胜率
        bsrday: '',//比赛日回显的日期
        month: ' ', //月份
        monthOptions: [], //月份下拉选项列表
        page: 1,
        total: 0, //页码
        pagesize: 20,
    }),
    components: {
        Table,
        performance,
        accountRating,
        scrollText,
        blueTab
    },
    created(){
        let vm = this;
        vm.tabActive = '-1'
        vm.tabClick(vm.tabList[0])
        
        vm.getPerformance()
        // vm.getTableList()
        vm.getScrollList()
    },
    watch:{
        tabActive(val,oldVal){
            let vm = this
            new Promise((resolve, reject) => {
                if (val == "0") {
                    vm.GetMonthList(vm.getTableList,resolve)
                    // vm.getTableList()
                    
                } else if (val == "1") {
                    vm.GetMonthList(vm.getTableDayList,resolve)
                    // vm.getTableDayList()
                }
            }).then(function(){
                if(oldVal!=-1){
                    vm.$refs.tableTab.setTabValue(vm.tabActive);
                    vm.realTabActive = vm.tabActive;
                }
            })
        }
    },
    // mounted(){
    //     let ifpop = localStorage.getItem('ifpop')
    //     if(ifpop==0){
    //         this.$alert('<div class="alertText">为了保证“比赛日”规则的公平性，自11月23日起对“比赛日”的获奖指标胜率、盈亏比算法进行如下优化：</div>\
    //         <div class="alertText">原计入比赛日成绩规则：比赛日当天开仓且在当天平掉的计入比赛成绩；</div>\
    //         <div class="alertText">现计入比赛日成绩规则优化为：比赛日当天开仓且在当天平仓，以平仓价计入比赛成绩；当天开仓但未平的仓以合约当日收盘价计入比赛成绩。</div>', 
    //         '“比赛日”排名指标算法优化',
    //         {
    //           dangerouslyUseHTMLString: true,
    //           center: true,
    //           customClass:'popBox'
    //         });
    //         localStorage.setItem("ifpop",1)
    //     }
    // },
    methods:{
        //请求排名数据
        getData(val) {
            let vm = this;
            vm.month = val
            vm.page = 1

            if(vm.tabActive == "0"){
                vm.getTableList()
            }else if(vm.tabActive == "1"){
                vm.getTableDayList()
            }
        },
        async GetMonthList(func,resolve) {
            let vm = this;
            vm.loading = true;
            let param = {
                // type: (vm.tabActive == '0' || vm.tabActive == '1') ? 0 : 1
                type: vm.tabActive
            }
            let { data, status } = await GetMonthList(param);
            vm.loading = false;
            if (status) {
                vm.monthOptions = [];
                if (data && data.length != 0) {
                    //初始化时，日期默认上个结算日/月
                    vm.month = (data[0] && data[0].mon) || ' '
                    // vm.month = (data[data.length-1]&&data[data.length-1].mon) || ' '
                    vm.monthOptions = data.map(item => {
                        item.value = item.mon;
                        item.mon = item.rqname
                        // item.mon = (vm.tabActive == '0') ? (item.mon.substr(0, 4) + '年' + item.mon.substr(4, 2)) : (item.mon.substr(0, 4) + '年' + item.mon.substr(4, 2) + '月' + item.mon.substr(6, 2))+"日"
                        return item;
                    });
                }

            } else {
                vm.monthOptions = [];
            }
            func && func(resolve)
        },
        //大赛公告滚动条
        async getScrollList(){
            let { data, status } = await ScrollListGet()
            if(status){
                if(data && data.length != 0){
                    // this.scrtext = data[0].Title + ' ' + this.scrtext;
                }
            }
        },
        //获取个人成绩
        async getPerformance(){
            let vm = this;
            let whid = localStorage.getItem('whid');
            let phone = localStorage.getItem('phone')
            if(!whid || whid=='') return
            vm.whid = whid;
            vm.p_loading = true;
            let param = {
                whid: vm.whid,
                phonenum: phone
            }
            let {data,status} = await Performance(param);
            if(status){
                vm.userdata = data;
            }
            vm.p_loading = false;
        },
        //获取月份
        getmonth(){
            // let vm = this;
            // //获取当前月份
            // let date = new Date();
            // vm.year = date.getFullYear();
            // vm.month = date.getMonth() + 1;
            // return vm.year + (vm.month < 10 ? '0' + vm.month : vm.month)
        },
        //获取表格数据 月冠军
        async getTableList(resolve) {
            let vm = this;
            vm.loading = true;
            // //获取当前月份
            // let date = new Date();
            // vm.year = date.getFullYear();
            // vm.month = date.getMonth() + 1;
            let month = vm.getmonth()
            let params = {
                // month: ' ',
                month: vm.month,
                // month: month,
                // zjzh: ' ',
                // Page: 1,
                // PageSize: 10
            }
            // let {data,status} = await GetRankList(params);
            let { data, status } = await GetMonthPrizeList(params);
            if(status){
                vm.tableData = data;
            }else{
                vm.tableData = [];
            }
            resolve && resolve()
            vm.loading = false;
        },
        // 获取比赛日表格
        async getTableDayList(resolve) {
            let vm = this;
            vm.loading = true;
            // //获取当前月份
            // let date = new Date();
            // vm.year = date.getFullYear();
            // vm.month = date.getMonth() + 1;
            let month = vm.getmonth()
            let params = {
                // month: ' ',
                month: vm.month,
                Page: vm.page,
                PageSize: vm.pagesize
                // month: month,
                // zjzh: ' ',
                // Page: 1,
                // PageSize: 10
            }
            let { data, status } = await GetDayPrizeList(params);
            // let { data, status } = await GetDayRankList(params);
            if (status) {
                // vm.tabActive = data.type + ""
                vm.daytype = data.type + ""
                vm.total = data.ntotal;
                if (data.type == 1) {
                    //类型：1 - 最大回撤比 2 - 盈亏比 3 - 胜率
                    /// 盈亏比 ykbrank 
                    /// 胜率slrank 
                    /// 最大回撤比 hcbrank 
                    vm.tableData = data.ccbrank;
                } else if (data.type == 2) {
                    vm.tableData = data.ykbrank;
                } else if (data.type == 3) {
                    vm.tableData = data.slrank;
                }
                vm.bsrday = data.gameday ? (data.gameday.substr(0, 4) + '-' + data.gameday.substr(4, 2) + '-' + data.gameday.substr(6, 2)) : ''
            } else {
                vm.tableData = [];
            }
            resolve && resolve()
            vm.loading = false;
        },

        //跳转到排行页
        goRanking(){
            // this.$router.push('/ranking')
            this.$router.push({
                // name: "ranking",
                // params: {
                //     acitve: this.tabActive
                // }
                path: "/ranking",
                query: {
                    acitve: this.tabActive
                }
            });
        },
        //跳转到说明页
        goDescription(){
            this.$router.push('/description')
        },
        // 跳转到大赛获奖名单
        godetail(){
            this.$router.push('/winnerList')
        },
        // tab切换
        tabClick(item) {
            let vm = this;
            vm.tabActive = item.value;
            // if (item.value == "0") {
            //     vm.getTableList()
            // } else if (item.value == "1") {
            //     vm.getTableDayList()
            // }
        },
        currentChange(val) {
            let vm = this;
            vm.page = val;
            vm.getTableDayList()
            // if (vm.bsrbool){
            //     vm.tabActive = "1"
            // }
        }
    }
}
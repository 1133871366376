import vue from '../../main';
let main = {}
//超长数字 显示 XX万、XX亿
main.handleNum = function (num) {
  let unitlist = ["", "万", "亿", "兆", '京', '垓', '杼'];
  let strnum = num.toString(); // 将数字转为字符串
  let unit = '';
  unitlist.find(item => { // 循环遍历单位数组
    let newNum = '';
    if (strnum.indexOf('.') !== -1) {
      newNum = strnum.substring(0, strnum.indexOf('.')); // 将小数点前的字符截取出来
    } else {
      newNum = strnum;
    }
    if (newNum.length < 5) {
      // 判断一下经过小数点截取后的金额字符长度是否小于5，若小于5则接收当前单位，并跳出迭代
      unit = item;
      if(unit!=''){ // 保留2位小数
        strnum = (strnum * 1).toFixed(2);
      }
      return true
    } else {
      // 若不小于5则将经过小数点截取处理过后的字符除以10000后作为下一轮迭代的初始金额重新判断(每一个单位之间相距4位数，故除以10000)
      strnum = (newNum * 1 / 10000).toString()
    }
  })
  let obj = {num: strnum, unit: unit}
  return obj
}

export default main;
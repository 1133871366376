import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import vuescroll from 'vuescroll';
import lifeCall from './plugins/lifeCall'

import 'core-js/stable';
import 'regenerator-runtime/runtime';

Vue.use(ElementUI);
Vue.use(lifeCall);
Vue.config.productionTip = false
Vue.use(vuescroll, {
  ops: {
    scrollPanel: {
      scrollingX: false
    },
    rail: {
      opacity: 1,
      keepShow: false,
      background: '#DFDFDF',
      border: '1px solid #DFDFDF'
    },
    bar: {
      opacity: 1,
      keepShow: true,
      background: '#F8F6F7'
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

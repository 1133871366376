<template>
<div class="tableFlex table33">
    <table  cellspacing="0" cellpadding="0">
        <thead>
            <th colspan="4">盈亏对比</th>
        </thead>
        <tr class="trtitle">
            <th>名称</th>
            <th style="width:50%;">全部交易</th>
            <!-- <th style="width:44px;">多头</th>
            <th style="width:44px;">空头</th> -->
        </tr>
        <tbody v-if="zjList">
            <tr style="border:none">
                <td>总盈利</td>
                <td>{{zjList.zyl}}</td>
            </tr>
            <tr>
                <td>总亏损</td>
                <td>{{zjList.zks}}</td>
            </tr>
            <tr>
                <td>持仓盈亏</td>
                <td>{{zjList.fdyk}}</td>
            </tr>
            <tr>
                <td>胜率</td>
                <td>{{parseInt(zjList.sl * 100)}}%</td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr style="border:none">
                <td colspan="4" class="noData">暂无数据</td>
            </tr>
        </tbody>
    </table>
    <table  cellspacing="0" cellpadding="0">
        <thead>
            <th colspan="4">平均盈亏</th>
        </thead>
        <tr class="trtitle">
            <th>名称</th>
            <th style="width:50%;">全部交易</th>
            <!-- <th style="width:44px;">多头</th>
            <th style="width:44px;">空头</th> -->
        </tr>
        <tbody v-if="zjList">
            <tr style="border:none">
                <td>平均每次收益</td>
                <td>{{zjList.pjmcsy}}</td>
            </tr>
            <tr>
                <td>平均每笔盈利</td>
                <td>{{zjList.pjmcyl}}</td>
            </tr>
            <tr>
                <td>平均每笔亏损</td>
                <td>{{zjList.pjmcks}}</td>
            </tr>
            <tr>
                <td>盈亏比</td>
                <td>{{zjList.ykb == '999999' ? "999..." : zjList.ykb}}</td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr style="border:none">
                <td colspan="4" class="noData">暂无数据</td>
            </tr>
        </tbody>
    </table>
    <table  cellspacing="0" cellpadding="0">
        <thead>
            <th colspan="4">最大笔盈亏</th>
        </thead>
        <tr class="trtitle">
            <th>名称</th>
            <th style="width:50%;">全部交易</th>
            <!-- <th style="width:44px;">多头</th>
            <th style="width:44px;">空头</th> -->
        </tr>
        <tbody v-if="zjList">
            <tr style="border:none">
                <td>最大单笔盈利</td>
                <td>{{zjList.maxmcyl}}</td>
            </tr>
            <tr>
                <td>最大单笔亏损</td>
                <td>{{zjList.maxmcks}}</td>
            </tr>
            <tr>
                <td>最大盈利/总盈利</td>
                <td>{{zjList.zdylbl}}</td>
            </tr>
            <tr>
                <td>最大亏损/总亏损</td>
                <td>{{zjList.zdksbl}}</td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr style="border:none">
                <td colspan="4" class="noData">暂无数据</td>
            </tr>
        </tbody>
    </table>
</div>
</template>
<script>
export default {
    props:{
        zjList: Object
    }
}
</script>
<style scoped src="./style.css"></style>
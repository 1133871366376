import { NewsListGet } from "../../assets/js/request";
export default{
    data: () => ({
        wlist:[], //总数据
        showlist:[], //当前显示的数据
        loading:false,
        page: 1,
        total: 0, //页码
        pagesize: 5, //一页显示的条数
        detail:false,//是否显示获奖详情
        detailitem:'' //获奖内容
    }),
    created(){
        this.NewsListGet()
    },
    methods:{
        async NewsListGet(){
            this.loading = true
            let { data ,status} = await NewsListGet()
            this.loading = false
            if (status){
                this.wlist = data
                this.total = data.length + 1
                this.pagechange(1)
            }
        },
        // 自制分页
        pagechange(val){
            let temppage = val - 1
            this.showlist = this.wlist.slice(temppage*5, val*5)
            // console.log(this.showlist)
        },
        // 分页
        currentChange(val){
            this.page = val
            this.pagechange(val)
        },
        // 详情
        detailcontent(item){
            this.detail = true
            let el = document.createElement('textarea')
            el.innerHTML = item.Body
            item.Body = el.value
            this.detailitem = item
        },
        // 截取时间
        timesplice(val){
            if(val){
                return val.slice(0, 4) + '/' + val.slice(5, 7) + '/' + val.slice(8, 10)
            }else{
                return ''
            }
        },
        // 截取详细时间
        timesplicedetail(val) {
            if (val) {
                return val.slice(0, 4) + '/' + val.slice(5, 7) + '/' + val.slice(8, 10) + ' ' + val.slice(11)
            } else {
                return ''
            }
        }
    }
}
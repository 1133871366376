import {GetAppUpdateList} from '../../assets/js/request'
export default {
    data: () => ({
        version1: '',
        version2: '',
        size1: '',
        size2: '',
        data:[] // 大小数组
    }),
    created(){
        this.getList()
    },
    methods:{
        async getList() {
            let vm = this;
            vm.loading = true;
            let {data,status} = await GetAppUpdateList();
            if(status){
                vm.version1 = data[0].Version;
                vm.size1 = data[0].SoftSize;
                vm.version2 = data[1].Version;
                vm.size2 = data[1].SoftSize;
                vm.data = data
            }
            vm.loading = false;
        },
    }
}
import tab from '../../components/tab.vue'
import simpleTable from './components/simpleTable.vue'
import {GetGoodsList_NP} from '../../assets/js/request'
export default{
    components:{
        tab,
        simpleTable
    },
    data: () => ({
        tabList: [{
            name: '上期所',
            value: '上期所SHFE'
        }, {
            name: '大商所',
            value: '大商所DCE'
        }, {
            name: '郑商所',
            value: '郑商所CZCE'
        }, {
            name: '上期能源',
            value: '上期能源INE'
        }, {
            name: '中金所',
            value: '中金所CFFEX'
            }, {
            name: '广期所',
            value: '广期所GFEX'
            }],
        totalList: [], //表格数据汇总
        tableList: [], //当前展示的表格内容
        loading: false
    }),
    created(){
        this.getList()
    },
    methods:{
        //获取表格数据
        async getList(){
            let vm = this;
            vm.loading = true;
            let {data,status} = await GetGoodsList_NP();
            if(status){
                vm.totalList = data;
                vm.tabClick(vm.tabList[0])
            }else{
                vm.totalList = [];
            }
            vm.loading = false;
        },
        //通过item获取表格信息
        tabClick(item){
            let vm = this;
            if(vm.totalList.length>0){
                vm.tableList = vm.totalList.filter(el=>{
                    return el.jysmc == item.value;
                })[0].list;
            }else{
                vm.tableList = [];
            }
        },
    }
}
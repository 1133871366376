<template>
	<div class="mnjy">
		<!-- <div class="outer" v-if="$route.name != 'result'"> -->
			<Header v-if="$route.name != 'result'"></Header>
			<div class="tm" :class="{'f_tm' : $route.name!='homePage',none:$route.name == 'result'}"></div>
			<div :class="{'elesContent' : $route.name!='homePage',content:$route.name != 'result',outer1:$route.name == 'result'}">
				<div class="title" v-if="$route.name!='homePage' && $route.name != 'result'">{{$route.meta.title}}</div>
				<router-view class="all" :class="{'elesAll' : $route.name!='homePage'}"/>
			</div>
			<div class="bg1" v-if="$route.name=='homePage'"></div>
			<div class="bg2" v-if="$route.name=='homePage'"></div>
			<Footer v-if="$route.name != 'result'"></Footer>
		<!-- </div>
		<div class="outer1" v-else>
			<router-view/>
		</div> -->
		
	</div>
</template>
<script>
import Header from './components/header.vue'
import Footer from './components/footer.vue'
export default {
	components:{
		Header,Footer
	},
	// created(){
	// 	localStorage.setItem("ifpop",0) //每次刷新页面，弹窗需要重新弹出
	// },
	mounted(){
		this.setContentHeight()
	},
	watch:{
		'$route': function(val){
			document.body.scrollTop = 0
			document.documentElement.scrollTop = 0
			this.setContentHeight()
		}
	},
	methods:{
		setContentHeight(){
			this.$nextTick(()=>{
				if(this.$route.name!='homePage'){
					let scrollHeight = this.getClientHeight();
					let contentHeight = scrollHeight - 250 + 15 - 91 - 60; //60是减去padding占位
					document.getElementsByClassName('elesContent')[0].style.minHeight = contentHeight + 'px';
					document.getElementsByClassName('elesContent')[0].childNodes[1].style.minHeight = contentHeight + 'px';
				}
			})
		},
		// 取窗口可视范围的高度
		getClientHeight(){
			let clientHeight=0;
			if(document.body.clientHeight&&document.documentElement.clientHeight){
				clientHeight = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
			}else{
			    clientHeight = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
			}
			return clientHeight;
		}
	}
}
</script>
<style>
.mnjy {
	font-family: Avenir, Helvetica, Arial, sans-serif,microsoft yahei;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	position: relative;
	width: 100%;
	min-height: 100%;
	min-width: 1500px;
	font-size: 16px;
	color: #000;
	background: url(./assets/image/homePage/bg_00.jpg) repeat;
	background-size: cover;
}
.mnjy::before {
    content: '';
    background: url(./assets/image/homePage/bg_01.png) center top no-repeat;
    background-size: auto;
    position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
    /* z-index: 1; */
}
.outer{
	width: 100%;
    min-height: 100%;
	box-sizing: border-box;
	position: relative;
	display: contents;
}
.outer1{
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
}
.tm {
	position: relative;
	background: url(./assets/image/homePage/Tm_01.png) center top no-repeat;
    background-size: auto;
	z-index: 3;
	width: 100%;
    height: 420px;
}
.f_tm {
	position: relative;
	background: url(./assets/image/homePage/f_TM_01.png) center bottom no-repeat;
    background-size: auto;
    z-index: 3;
    width: 100%;
    height: 250px;
}
.none{
	display: none;
}
.content {
	width: 1064px;
	margin: 0 auto;
	/* position: relative;
	z-index: 5; */
	background: rgb(255, 255, 255);
    box-shadow: rgb(111 146 199 / 20%) 0px 0px 4px;
	padding-bottom: 91px;
}
.content .title {
	position: absolute;
    top: 160px;
    font-size: 36px;
    color: #fff;
    font-weight: 600;
	text-shadow: 1px 2px 1px rgb(108 114 122 / 30%);
}
.elesContent {
	z-index: 2;
	margin-top: -15px;
}
.mnjy .all {
	position: relative;
	top: -34px;
	background: #fff;
}
.elesAll {
	top: 0!important;
	padding: 30px 24px ;
}
.bg1 {
	background: url(./assets/image/homePage/bg_02.png) center no-repeat;
	background-size: contain;
	width: 100%;
    position: absolute;
    height: 340px;
    top: 990px;
}
.bg2 {
	background: url(./assets/image/homePage/bg_03.png) center no-repeat;
	background-size: contain;
	width: 100%;
    height: 240px;
    position: absolute;
    bottom: 145px;
	z-index: -1;
}
/* table{
	font-family: SimSun,'宋体'!important;
} */
</style>

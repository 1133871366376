<template>
    <div>
        <div>
            <router-link
                class="menu"
                v-for="(item,index) in menuList"
                :key="index"
                :to="item.to"
                :class="{last:index == menuList.length-1}">
                {{item.name}}
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    data:() => ({
        menuList:[{
            name:'首页',
            to:'/homePage'
        },{
            name:'排行榜',
            to:'/ranking'
        },{
            // name:'模拟软件',
            // name:'交易软件',
            // name:'参赛软件',
            name:'交易软件',
            to:'/software'
        },{
            name:'模拟豆',
            to:'/mimeticBean'
        },{
            name:'交易规则',
            to:'/tradeRules'
        },{
            name:'活动说明',
            to:'/description'
        },{
            name:'个人中心',
            to:'/personalCenter'
        }]
    })      
}
</script>
<style scoped>
.menu {
    font-family: SimSun,'宋体';
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    margin-right: 35px;
    display: inline-block;
    /* height: 48px; */
    height: 38px;
    line-height: 48px;
    padding: 0 3px;
    user-select: none;
    font-weight: 600;
    opacity: 0.8;
}
.menu.router-link-active {
    border-bottom: 2px solid #fff;
    opacity: 1;
}
.menu.last {
    margin-right: 0;
}
</style>
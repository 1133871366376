let data = {}

let callBack = function () {
    // lifeCall
    this.$lifeCall = function (call) {
        let id = this._uid
        data[id] = true
        return function () {
            if (data[id]) {
                let params = Array.prototype.slice.apply(arguments)
                call.apply(null, params)
            }
        }
    }
}

let destroyCall = function () {
    let id = this._uid
    delete data[id]
}

export default function install(Vue, opt = {}) {

    Vue.mixin({
        created: callBack,
        destroyed: destroyCall,
        activated: callBack,
        deactivated: destroyCall
    })

};

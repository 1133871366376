<template>
    <div class="center">
        <div class="c_flex">
            <div class="subflex">
                <div class="personimg"></div>
                <!-- <span v-if="ifquik">（已退赛）</span> -->
                <div class="whidtext">你好，<span style="font-size:13px;">{{username}}</span></div>
                <div class="link" style="margin:0 20px;" v-if="ifquik == 1" @click="reopen">重新参赛</div>
                <div class="link" @click="$emit('showDialog','修改昵称','modifyName')">修改昵称</div>
                <div class="link quitlogin" v-if="IsPrize" @click="$emit('showDialog','登记南华期货账户','naHua',pricedata)">去完善领奖信息</div>
                <div class="link quitlogin" @click="quitlogin">退出</div>
            </div>
            <div class="subflex" style="color:rgb(61,67,76)">
                <div class="beantext">模拟豆数</div>
                <div class="beannum">{{beannum}}</div>
                <div class="beanunit">个</div>
                <div class="beanimg" @click="$emit('showDialog','','buy')"></div>
                <div class="link" style="margin-right:10px;" @click="$emit('showDialog','','billBuy')">查看购买记录</div>
                <div class="link" @click="$emit('showDialog','','bill')">查看流水记录</div>
            </div>
        </div>
        <div v-if="ifquik == 0">
            <div class="c_content">
                <div class="centertitle">个人战绩</div>
                <div v-loading="loading1" class="outerline">
                    <div class="flexperson">
                        <div>参赛日期:{{zjList.csrq}}</div>
                        <div>报告日期:{{zjList.bcrq}}</div>
                        <div>报告天数:{{zjList.csts}}</div>
                    </div>
                    <zjTable1 :zjList="zjList"></zjTable1>
                    <zjTable2 :zjList="zjList" style="margin: 8px 0;"></zjTable2>
                </div>

                <div class="centertitle" style="margin-top:41px;margin-bottom: 13px;">我的排名</div>
                <div v-loading="loading2" class="outertable">
                    <ykbTable class="topstand" v-show="showlist == 3" :showmore="showtext" :ykblist="showmore ? ykblist.slice(0,8) : ykblist"></ykbTable>
                    <slTable class="topstand" v-show="showlist == 2" :showmore="showtext"  :sllist="showmore ? sllist.slice(0,8) : sllist"></slTable>
                    <ccbTable class="topstand"  v-show="showlist == 1" :showmore="showtext"  :ccblist="showmore ? ccblist.slice(0,8) : ccblist"></ccbTable>
                    <blueTab :tabList="tabList" @tabClick="tabClick" class="tabs" style="margin: 15px 0 10px;" ref="tableTab"></blueTab>
                    <!-- <allTable @change="changetype"></allTable> -->
                    <div class="tableouter">
                        <!-- <zdhcbTable :ylelist="hcblist"></zdhcbTable> -->
                        <!-- <yleTable :ylelist="ylelist"></yleTable> -->
                        <ykbTable v-show="showlist == 3"  :ykblist="showmore ? ykblist.slice(0,8) : ykblist"></ykbTable>
                        <slTable v-show="showlist == 2"  :sllist="showmore ? sllist.slice(0,8) : sllist"></slTable>
                        <ccbTable v-show="showlist == 1"  :ccblist="showmore ? ccblist.slice(0,8) : ccblist"></ccbTable>
                    </div>
                    <div class="more" @click="more" v-show="showtext">{{showmore ? '显示更多' : '收起'}}</div>
                    
                    <yzhTable :monlist="monlist"></yzhTable>
                </div>
            </div>
            <div class="quik" @click="$emit('showDialog','退赛','quit')">退赛</div>
        </div>
        <!-- 未参赛提示文字 -->
        <div v-if="ifquik == 2" style="color: #676767;margin: 30px 0;">
            未参赛，点击<router-link class="link" style="font-size: 15px;" :to="{path:'/description',query:{id:'two'}}" target="_blank"> 查看参赛条件</router-link>
        </div>
        <!-- <div v-if="ifquik == 1"  style="color: #676767;margin: 30px 0;">
            已退赛
        </div> -->
    </div>
</template>
<script>
import { DetailInfo,MyRankList,FundInfo,NicknameGet,ReJoin } from "../../../assets/js/request";
import zjTable1 from "../components/table/zjTable1.vue"
import zjTable2 from "../components/table/zjTable2.vue"
import yzhTable from "../components/table/yzhTable.vue"
import yleTable from "../components/table/yleTable.vue"
import ykbTable from "../components/table/ykbTable.vue"
import slTable from "../components/table/slTable.vue"
import ccbTable from "../components/table/ccbTable.vue"
import zdhcbTable from "../components/table/zdhcbTable.vue"
// import allTable from "../components/table/allTable.vue"
import blueTab from '../../../components/blueTab.vue'
export default {
    name: 'center',
    data: () => ({
        beannum:'0',  //模拟豆个数
        zjList:{}, //个人战绩列表
        monlist:[],
        sllist:[],
        ykblist:[],
        ccblist:[],
        ylelist:[],
        hcblist:[],
        loading1:false,
        loading2:false,
        username:'',
        ifquik:0, //是否退赛  1:已退赛 2:无参赛资格
        // ifjoin:true //是否参赛
        phone:'',
        query:'', //home表示从首页点击进入的
        IsPrize:false ,//是否中奖
        pricedata:{}, //获奖人信息
        showlist:3, //默认显示“比赛日”盈亏比 为3 “比赛日”胜率为2 “比赛日”产出比为1
        showmore:true, //显示更多、收起 切换
        showtext:false,//是否显示更多
        tabList:[
            { name:'“比赛日”盈亏比', value:3},
            { name: '“比赛日”胜率' ,value:2},
            { name: '“比赛日”产出比' ,value:1}
            ]
    }),
    components:{
        zjTable1,
        zjTable2,
        yzhTable,
        yleTable,
        ykbTable,
        slTable,
        ccbTable,
        zdhcbTable,
        // allTable
        blueTab
    },
    props:{
        whid:String,
        ischangename:{
            type:Boolean,
            default:false
        },
    },
    watch:{
        ischangename(val){
            this.nickname();
            this.$emit("update:ischangename",false)
        }
    },
    created() {
        let vm = this;
         // 获取参数
        this.query = this.$route.query ? this.$route.query.from : ''
        vm.phone = localStorage.getItem('phone');
        if(this.query == 'home'){
            this.$router.push("/homePage")
        }else{
            vm.getBean();
            vm.getzj();
            vm.getRank();
            vm.nickname();
        }
    },
    methods: {
        //模拟豆余额
        async getBean(){
            let vm = this;
            let phone = localStorage.getItem('phone')
            let param = {
                whid: vm.whid,
                phonenum: phone
            }
            let {data,status} = await FundInfo(param);
            if(status){
                if(data.fundlist.length>0){
                    vm.beannum = data.fundlist[0].zjye;
                }
            }
        },
        //个人战绩列表
        async getzj(){
            let vm = this;
            vm.loading1 = true;
            let phone = localStorage.getItem('phone')
            let param = {
                whid: vm.whid,
                phonenum: phone
            }
            let {data,status} = await DetailInfo(param);
            if(status){
                vm.zjList = data;
            }
            vm.loading1 = false;
        },
        //我的排名列表
        async getRank(){
            let vm = this;
            vm.loading2 = true;
            let phone = localStorage.getItem('phone')
            let param = {
                whid: vm.whid,
                phonenum: phone
            }
            let {data,status} = await MyRankList(param);
            if(status){
                // let {monlist,sllist,ykblist,ylelist} = data;
                let {monlist,sllist,ccblist,ykblist,hcblist} = data;
                vm.monlist = monlist;
                vm.sllist = sllist;
                vm.ykblist = ykblist;
                vm.ccblist = ccblist;
                // vm.ylelist = ylelist;
                vm.hcblist = hcblist

                // vm.ykblist = vm.ykblist.concat(ykblist)
                // vm.ccblist = vm.ccblist.concat(ccblist)
                // vm.sllist = vm.sllist.concat(sllist)

                // 初始化点击事件
                vm.tabClick(vm.tabList[0])


                // //获取昵称
                // if(vm.monlist.length>0 && vm.monlist[0].nc!=''){
                //     vm.username = vm.monlist[0].nc;
                // }else {
                //     vm.username = vm.whid;
                // }
            }
            vm.loading2 = false;
        },
        //昵称获取
        async nickname(){
            let vm = this
            let phone = localStorage.getItem('phone')
            let data = await NicknameGet({whid:this.whid,phonenum: phone})
            if(data.status){
                 //获取昵称
                // vm.username = data.data.nc.trim() ? data.data.nc : vm.whid;
                vm.username = data.data.nc.trim() ? data.data.nc : vm.phone;
                // zt字段 0是参赛 1和-1是退赛
                // 0是参赛  -1 是客户主动退赛 1是自动退赛
                // 2022-0819 zt=-1  理解为他符合参赛资格，但是一直没交易，没有扣过豆，然后排行榜也没进哈
                if(data.data.zt != -"1"){
                    vm.ifquik = data.data.zt==0 ? 0 : 1;
                }
                if(data.data.IsPrize == 1){
                    vm.IsPrize = true
                    vm.pricedata = data.data
                    // 查询是否中奖，如果中奖同时填过中奖人信息，给返回用户填的中奖信息（人名、账号），如果没填过中奖人信息，返回实名认证的姓名 是否中奖 1-已中奖
                }
                // vm.ifjoin = true
            }else{
                // vm.username = vm.whid;
                vm.username = vm.phone
                vm.ifquik = 2 //未参赛
                // vm.ifjoin = false
            }
           
        },
        quitlogin(){
            localStorage.setItem("token", '')
            localStorage.setItem("whid", '')
            localStorage.setItem("phone", '')
            location.reload();
        },
        async reopen(){
            let phone = localStorage.getItem('phone')
            let data = await ReJoin({whid:this.whid,phonenum: phone})
            // if(data.status){
                this.$alert(data.message, '提示', {
                    confirmButtonText: '确定',
                    showClose:false,
                    callback: action => {
                        if(data.status){
                            location.reload()
                        }
                    }
                });
            // }
            
        },
        more(){
            this.showmore = !this.showmore
        },
        tabClick(item){
            this.showlist = item.value
            this.$refs.tableTab.setTabValue(item.value);
            this.showtext = true
            if(this.showlist == 3){
                this.ykblist.length > 8 ? this.showmore = true:this.showtext = false
            }else if(this.showlist == 2){
                this.sllist.length > 8 ? this.showmore = true:this.showtext = false
            }else if(this.showlist == 1){
                this.ccblist.length > 8 ? this.showmore = true:this.showtext = false
            }
        }
    }
}
</script>
<style scoped src="./style.css"></style>
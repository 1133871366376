<template>
<div class="tableFlex table50">
    <table cellspacing="0" cellpadding="0">
        <thead>
            <th colspan="4">权益概况</th>
        </thead>
        <tr class="trtitle">
            <th>名称</th>
            <th style="width:50%">全部交易</th>
            <!-- <th style="width:80px;">多头</th>
            <th style="width:80px;">空头</th> -->
        </tr>
        <tbody v-if="zjList">
            <tr style="border:none">
                <td>期初权益</td>
                <td>{{zjList.csqy}}</td>
            </tr>
            <tr>
                <td>累计入金</td>
                <td>{{zjList.ljcr}}</td>
            </tr>
            <tr>
                <td>累计出金</td>
                <td>{{zjList.ljqc}}</td>
            </tr>
            <tr>
                <td>期末权益</td>
                <td>{{zjList.szqy}}</td>
            </tr>
            <tr>
                <td>期间最大权益</td>
                <td>{{zjList.maxqy}}</td>
            </tr>
            <tr>
                <td>期间最小权益</td>
                <td>{{zjList.minqy}}</td>
            </tr>
            <tr>
                <td>年化单利收益率</td>
                <td>{{zjList.syl}}</td>
            </tr>
            <tr>
                <td>月化单利收益率</td>
                <td>{{zjList.ysyl}}</td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr style="border:none">
                <td colspan="4" class="noData">暂无数据</td>
            </tr>
        </tbody>
    </table>
    <table  cellspacing="0" cellpadding="0">
        <thead>
            <th colspan="4">收益与回撤</th>
        </thead>
        <tr class="trtitle">
            <th>名称</th>
            <th style="width:50%">全部交易</th>
            <!-- <th style="width:80px;">多头</th>
            <th style="width:80px;">空头</th> -->
        </tr>
        <tbody v-if="zjList">
            <tr style="border:none">
                <td>累计盈利额</td>
                <td>{{zjList.ljsy}}</td>
            </tr>
            <tr>
                <td>累计净值</td>
                <td>{{zjList.jz}}</td>
            </tr>
            <tr>
                <td>权益最大回撤</td>
                <td>{{zjList.maxqyhc}}</td>
            </tr>
            <tr>
                <td>权益最大回撤比</td>
                <td>{{zjList.maxqyhcbl}}</td>
            </tr>
            <tr>
                <td>风险率</td>
                <td>{{zjList.fxd}}</td>
            </tr>
            <tr></tr>
            <tr></tr>
            <tr></tr>
        </tbody>
        <tbody v-else>
            <tr style="border:none">
                <td colspan="4" class="noData">暂无数据</td>
            </tr>
        </tbody>
    </table>
</div>
</template>
<script>
export default {
    props:{
        zjList: Object
    }
}
</script>
<style scoped src="./style.css"></style>
<template>
<div>
    <div class="rankTitle">“比赛日”最大回撤比</div>
    <table class="rank"  cellspacing="0" cellpadding="0">
        <thead>
            <th>日期</th>
            <th>排名</th>
            <th>期初权益</th>
            <th>盈利额</th>
            <th>当日权益</th>
            <th>当日净值</th>
            <th>最大权益</th>
            <th>最大回撤</th>
            <th>交易笔数</th>
            <th>权益最大回撤比</th>
        </thead>
        <tbody v-if="ylelist.length>0">
            <tr v-for="(item,index) in ylelist" :key="index" style="border:none;">
                <td>{{item.bcrq}}</td>
                <td>{{item.rankid}}</td>
                <td>{{item.zrqy}}</td>
                <td>{{item.dryl}}</td>
                <td>{{item.szqy}}</td>
                <td>{{item.drjz}}</td>
                <td>{{item.maxqy}}</td>
                <td>{{item.maxhc}}</td>
                <td>{{item.jycs}}</td>
                <td>{{item.zdhcbl}}</td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr style="border:none">
                <td v-for="(i,index) in 10" :key="index">--</td>
                <!-- <td colspan="7" class="noData">暂无数据</td> -->
            </tr>
        </tbody>
    </table>
</div>
</template>
<script>
export default {
    props:{
        ylelist: Array
    }
}
</script>
<style scoped src="./style.css"></style>
<template>
  <div class="report">
    <div class="reportnum">
      <span>{{numobj ? numobj.num : '—'}}</span>
      <span v-if="numobj && numobj.unit!=''" style="margin-left:3px;font-size: 14px">{{numobj && numobj.unit}}</span>
    </div>
    <div class="reporttext">{{text}}</div>
  </div>
</template>

<script>
import { number } from 'echarts'
export default {
    name: 'report',
    data: () => ({
    }),
    props:{
      numobj: Object,
      text: String,
    },
    methods: {
    }
}
</script>
<style scoped>
.report{
  color: #4275B8;
  text-align: center;
  min-width: 76px;
  padding: 0 5px;
}
.reportnum{
  font-size: 20px;
  margin-bottom: -2px;
}
.reporttext{
  font-size: 14px;
}
</style>
<template>
 <div class="flex">
    <div class="verify" :class="{verifyafter:showver}" @click.stop="verify()">
        <Verify @loaded="loaded"></Verify>
        <div id="sc" style="margin:0 2px;"></div>
    </div>
    <div class="simtext" style="width:45px;flex-shrink:0;">验证码</div>
    <div style="flex-grow: 1;">
        <el-input
            class="simInput"
            placeholder="请输入验证码"
            v-model="code"
            clearable>
        </el-input>
    </div>
    <div class="simcode" :class="{grey:!sendBtn}">{{checkCode_text}}</div>
</div>
</template>
<script>
let timer = null
import {SimulationSendCode} from "../../../assets/js/request";
export default {
    data: () => ({
        sessionid:'',
        sig:'',
        token:'',
        showver:false, //点击智能验证后显示
        checkCode_text:'获取',
        sendBtn:true,//验证码按钮状态
        code:'', //验证码
        ic:null
        // ifchange:false //是否在没有收到回调的时候切换tab
    }),
    components: {
        Verify: {
            render(createElement) {
                const self = this;
                return createElement("script", {
                attrs: {
                    type: "text/javascript",
                    src: "//g.alicdn.com/sd/nvc/1.1.112/guide.js",
                },
                on: {
                    load() {
                        self.$emit("loaded");
                    },
                },
                });
            },
        },
    },
    props:{
        mess:{
            type:String,
            default:''
        },
        phone:String,
        simcode:String,
        reset:Boolean
    },
    created() {
        let vm = this
        window.NVC_Opt = {
            //应用类型标识。它和使用场景标识（scene字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
            appkey: 'FFFF0N0000000000914C',
            //使用场景标识。它和应用类型标识（appkey字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的scene值，请务必正确填写。
            scene: 'ic_login',
            //声明二次验证需要渲染的目标元素ID。
            renderTo: '#sc',
            //业务键字段，可为空。该参数可用于上线前测试，请按照代码集成后测试部分中的方法配置该字段值。
            trans: { "key1": "code0", "nvcCode": 200 },
        };
       
        // window.icdata = function (data) {
        //     // if(vm.ifchange) return
        //     vm.sessionid = data.sessionId
        //     vm.sig = data.sig
        //     vm.token = window.NVC_Opt.token
        // }
    },
    mounted() {
        this.init();
    },
    watch:{
        sessionid(val){
            console.log(val)
            this.showver = false
            this.sendBtn = false
            this.SimulationSendCode()
        },
        code(val){
            this.$emit("update:simcode",val)
        },
        reset(val){
            if(val){
                this.code = ''
                this.checkCode_text = '获取'
                clearInterval(timer);
                this.ic.reset()
            }
        }
    },
    methods: {
        loaded() {
            console.log("智能验证加载成功");
        },
        verify(){
            // console.log(this.showver)
            // console.log(this.ifchange)
            // console.log(this.sendBtn)
            if(!this.sendBtn) return
            // if(this.ifchange){
            //     window.ic.reset()
            // } 
            this.showver = true
            // this.ifchange = false
        },
        count_down() {
            var vm = this;
            var count = 1;
            var total_time = 60;
            vm.sendBtn = false;
            timer = setInterval(function () {
                if (count == total_time) {
                    vm.sendBtn =  true;
                    vm.checkCode_text = '获取';
                    vm.ic.reset()
                    clearInterval(timer);
                    return;
                }
                vm.checkCode_text = '重新发送' + '(' + (total_time - count) + ')';
                count++;
            }, 1000);
        },
        async SimulationSendCode(){
            let vm = this;
            // console.log(vm.phone_num)
            if(vm.phone==''){
                vm.$emit("update:mess",'请填写手机号码')
                vm.sendBtn = true
                vm.ic.reset()
                return;
            }
            if (!(/^\d{11}$/.test(vm.phone))) {
                vm.$emit("update:mess",'请填写正确的手机号码')
                vm.sendBtn = true
                vm.ic.reset()
                return;
            }
            vm.count_down()
            let data = await SimulationSendCode({Phone:vm.phone,sessionid:vm.sessionid,token:vm.token,sig:vm.sig,source:'MNTradingGame-competition-confrim' })

            if(!data.status){
                this.$emit("update:mess",data.message)
            }

        },
        init() {
            let vm = this;
            vm.ic = new smartCaptcha({
                renderTo: '#sc',
                //智能验证组件的宽度。
                width: 321,
                // width: '100%',
                //智能验证组件的高度。
                //height: 42,
                height: '100%',
                //智能验证组件初始状态文案。
                default_txt: '开始验证',
                //智能验证组件验证通过状态文案。
                success_txt: '验证成功',
                //智能验证组件验证失败（拦截）状态文案。
                fail_txt: '验证失败，请在此点击按钮刷新',
                //智能验证组件验证中状态文案。
                scaning_txt: '智能检测中',
                //前端智能验证通过时会触发该回调参数。您可以在该回调参数中将请求标识（token）、会话ID（sessionid）、签名串（sig）字段记录下来，随业务请求一同发送至您的服务端调用验签。
                success:  function (data) {
                    //返回的三个参数分别为sessionId,sig,token
                    vm.sessionid = data.sessionId
                    vm.sig = data.sig
                    vm.token = NVC_Opt.token
                    // vm.$emit("returnData",data.sessionId,data.sig,NVC_Opt.token)
                },
                fail: function (data) {
                    console.log("ic error", data);
                    vm.ic.reset()
                },
            });
            vm.ic.init();
        },
    }
}
</script>
<style scoped>
.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
}
/* 实名认证的手机号智能验证 */
.verify{
    width: 111px;
    height: 34px;
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 1;
    opacity: 0;
    overflow: hidden;
}
.verifyafter{
    width: 250px;
    height: 45px;
    opacity: 1;
    background: #fff;
}
.verify >>> #SM_BTN_1{
    width: 100% !important;
}
.simtext{
    display: flex;
    align-items: center;
    justify-content: right;
    width: 106px;
    /* letter-spacing: 1px; */
    margin-right: 5px;
    font-size: 15px;
    color: rgba(61, 67, 76,0.8);
}
.simcode{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 36px;
    margin-left: 5px;
    font-size: 14px;
    color: #fff;
    background-color:#C19D73;
    cursor: pointer;
    user-select: none;
}
</style>
<template>
    <div class="confirm">
        <el-dialog title="实名认证" width="546px" top='24vh' class="dialog" :visible.sync="comfirm" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" >
            <div slot="title" class="header-title" style="margin: -3px 0 2px 0;">
               <span class="stitle">实名认证</span> 
               <!-- <span style="font-size: 12px;color: #E67053;">请认真填写以下内容</span> -->
            </div>
            <div v-loading="loading">
                <div class="line"></div>
                <div style="text-indent: 2em;line-height:23px;">请填写真实信息，您提交的认证信息将影响后续的使用及发票开具。</div>
                <div class="tab">
                    <span class="tablist" :class="{'acive': tabactive == 0 }" @click="tabactive = 0" style="margin-right:6px;">个人用户</span>
                    <span class="tablist" :class="{'acive': tabactive == 1 }" @click="tabactive = 1">机构用户</span>
                </div>
                <div class="tabpanel" :class="{tabpanel1:tabactive == 1}">
                    <!-- 个人用户回显内容 -->
                    <div v-if="tabactive == 0">
                        <div class="flex">
                            <div class="tabtip">文华云账号</div>
                            <el-input class="input"  v-model="info.phone" placeholder="请输入联系电话" :disabled="true"></el-input>
                            <!-- <el-input class="input"  v-model="info.whid" placeholder="请输入文华云账号" :disabled="true"></el-input> -->
                        </div>
                        <div class="flex">
                            <div class="tabtip">真实姓名</div>
                            <el-input class="input" v-if="info.realname" v-model="info.realname" placeholder="请输入真实姓名" :disabled="true"></el-input>
                            <el-input class="input" v-else v-model="realname" placeholder="请输入真实姓名"></el-input>
                        </div>
                        <div class="flex">
                            <div class="tabtip">身份证号</div>
                            <el-input class="input" v-if="info.idcard" v-model="info.idcard" placeholder="请输入18位身份证号" :disabled="true"></el-input>
                            <el-input class="input" v-else v-model="idcard" placeholder="请输入18位身份证号"></el-input>
                        </div>
                        <div class="flex">
                            <div class="tabtip">联系电话</div>
                            <el-input class="input"  v-model="info.phone" placeholder="请输入联系电话" :disabled="true"></el-input>
                        </div>
                        <!-- <verfity :tabactive="tabactive" :whid="whid" :phone="phone" :percode.sync="percode" :message.sync="message"></verfity>                       -->
                    <!-- <div class="flex">
                            <div class="verify" :class="{verifyafter:showver}" @click.stop="verify()">
                                <div id="sc" style="margin:0 2px;"></div>
                            </div>
                            <div class="tabtip">验证码</div>
                            <el-input class="input" style="width: 200px;" v-model="percode" placeholder="请输入验证码"></el-input>
                            <div class="code" :class="{grey:!sendBtn}">{{checkCode_text}}</div>
                        </div> -->
                        <div class="buyred" style=" margin-right: -50px; margin-left: -19px;">首次赠送500个模拟豆可用于模拟交易，1个月服务卡可用于实盘交易。</div>
                        <div style="text-align:center;position: relative;">
                            <div class="message buyred">{{message}}</div>
                            <span class="btn" style="margin-left: 19px;" @click="up">提交验证</span>
                            <span class="btn" style="margin-left: 19px;" @click="quitlogin">取消</span>
                        </div>
                    </div>
                    <!-- 机构用户回显内容 -->
                    <div v-if="tabactive == 1">
                        <div class="flex">
                            <div class="tabtip">文华云账号</div>
                            <el-input class="input"  v-model="info.phone" placeholder="请输入联系电话" :disabled="true"></el-input>
                            <!-- <el-input class="input" v-if="info.whid" v-model="info.whid" placeholder="请输入文华云账号" :disabled="true"></el-input> -->
                        </div>
                        <div class="flex">
                            <div class="tabtip">公司名称</div>
                            <el-input class="input" v-model="companyname" placeholder="已开发票的同发票名头"></el-input>
                        </div>
                        <div class="flex">
                            <div class="tabtip">统一社会<br>信用代码</div>
                            <el-input class="input" v-model="companyCode" placeholder="未办理三证合一的公司提供组织机构代码"></el-input>
                        </div>
                        <div class="flex">
                            <div class="tabtip">真实姓名</div>
                            <el-input class="input" v-if="info.realname" v-model="info.realname" placeholder="请输入真实姓名" :disabled="true"></el-input>
                            <el-input class="input" v-else v-model="realname" placeholder="请输入真实姓名"></el-input>
                        </div>
                        <div class="flex">
                            <div class="tabtip">身份证号</div>
                            <el-input class="input" v-if="info.idcard" v-model="info.idcard" placeholder="请输入18位身份证号" :disabled="true"></el-input>
                            <el-input class="input" v-else v-model="idcard" placeholder="请输入18位身份证号"></el-input>
                        </div>
                        <div class="flex">
                            <div class="tabtip">联系电话</div>
                            <el-input class="input"  v-model="info.phone" placeholder="请输入联系电话" :disabled="true"></el-input>
                            <!-- <el-input class="input" v-else v-model="phone" placeholder="请输入联系电话" :disabled="true"></el-input> -->
                        </div>
                        <!-- <verfity :tabactive="tabactive" :whid="whid" :phone="phone" :percode.sync="percode" :message.sync="message"></verfity> -->
                        <!-- <div class="flex">
                            <div class="verify" :class="{verifyafter:showver}" @click.stop="verify()">
                                <div id="sc" style="margin:0 2px;"></div>
                            </div>
                            <div class="tabtip">验证码</div>
                            <el-input class="input" style="width: 200px;" v-model="concode" placeholder="请输入验证码"></el-input>
                            <div class="code" :class="{grey:!sendBtn}">{{checkCode_text}}</div>
                        </div> -->
                        <div class="buyred" style=" margin-right: -50px; margin-left: -19px;">首次赠送500个模拟豆可用于模拟交易，1个月服务卡可用于实盘交易。</div>
                        <div style="text-align:center;position: relative;">
                            <div class="message buyred">{{message}}</div>
                            <span class="btn" style="margin-left: 19px;" @click="up">提交验证</span>
                            <span class="btn" style="margin-left: 19px;" @click="quitlogin">取消</span>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>


<script>
import { WalletApi ,SendCode,AddUser} from "../../../assets/js/request";
import verfity from "../components/verfity.vue"
let timer = null
export default {
    name: 'confirm',
    data: () => ({
        tabactive:0,
        input:'',
        comfirm:false,
        info:{},
        loading:false,
        realname:'' ,//姓名
        idcard:'', //身份证
        sessionid:'',
        sig:'',
        token:'',
        phone:'',
        showver:false, //点击智能验证后显示
        checkCode_text:'获取验证码',
        sendBtn:true,//验证码按钮状态
        message:'', //错误提示
        // percode:'', //个人验证码
        // concode:'', //机构验证码
        companyCode:'' ,//社会统一信用码
        companyname:'' //公司名称

    }),
    components:{
        verfity
    },
    props:{
    //   comfirm:{
    //     type:Boolean,
    //     default:false
    //   }
        comfirmshow:{
            type:Boolean,
            default:false
        },
        whid:String
    },
    watch:{
        comfirmshow(val){
            this.comfirm = val
        },
        tabactive(val){
            this.message = ''
        }
    },
    created() {
        this.comfirm = this.comfirmshow
        this.init()
    },
    methods: {
        async init(){
            this.loading = true
            this.info =  await WalletApi({whid :this.whid })
            this.phone = this.info.phone
            this.info.phone = this.iphoneEncryption (this.info.phone)
            this.loading = false
        },
        iphoneEncryption (iphone) {
            var item = iphone ; 
            if(item.length == 11){
                return item.substring(0,3) + '*****' + item.substring(8)
            }else{
                return item.substring(0,3) + '****' + item.substring(7)
            }
        },
     
        async up(){
            let vm = this
            let param = {
                whid:vm.whid,
                accountType: vm.tabactive == 0 ? 'person' : 'company',
                idCard:vm.info.idcard ? vm.info.idcard : vm.idcard,
                // ValidCode:vm.percode,
                companyCode :vm.companyCode,
                contactName:vm.tabactive == 0 ? '' : (vm.info.realname ? vm.info.realname:vm.realname),
                userName:vm.tabactive == 0 ? (vm.info.realname ? vm.info.realname:vm.realname): vm.companyname
            }
            let data = await AddUser(param)
            console.log(data)
            if(!data.result.stauts){
                this.message  = data.result.message
            }else{
                this.$emit("update:comfirmshow",false)
                localStorage.getItem('confirm',1)
            }
        },
        quitlogin(){
            localStorage.setItem("token", '')
            localStorage.setItem("whid", '')
            localStorage.setItem("phone", '')
            location.reload();
        }
    }
}
</script>
<style scoped src="./style.css"></style>
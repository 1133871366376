import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:'' //登录后的token
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload
    },
  },
  actions: {
  },
  modules: {
  }
})

<template>
    <div class="header">
        <!-- <div class="title">文华期货模拟交易</div> -->
        <div class="title"></div>
        <navMenu></navMenu>
    </div>
</template>
<script>
import navMenu from './navMenu.vue'
export default {
    components:{
        navMenu
    }
}
</script>
<style scoped>
.header {
    font-size: 14px;
    z-index: 999;
    /* background-color: #0F4699; */
    /* opacity: 0.8; */
    background-color: rgba(15,70,153,0.8);
    padding: 0 calc(50% - 532px);
    height: 73px;
    line-height: 73px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
}
.header .title {

    /* font-size: 20px;
    font-weight: bolder;
    color: #fff; */
    /* width: 167px;
    height: 78px; */
    width: 92px;
    height: 44px;
    background: url(../assets/image/homePage/logo.png) no-repeat center center;
    background-size: contain;
    margin-top: 12px;
}
</style>
<template>
<div>
    <!-- <div class="rankTitle" style="margin-top:0;">“比赛日”盈亏比</div> -->
  <table class="rank" v-if="showmore"  cellspacing="0" cellpadding="0">
        <thead>
            <th style="width:10%">日期</th>
            <th>排名</th>
            <th>日内盈亏比</th>
            <th>平今笔数</th>
            <th>平今收益</th>

            <!-- <th>净利润</th> -->
            
            <!-- <th>当日期初权益</th>
            <th>当日期末权益</th>
            <th>当日净值</th> -->

            <th>总盈利</th>
            <th>盈利次数</th>
            <th>总亏损</th>
            <th>亏损次数</th>

            <th>当日权益</th>
            <th>当日净值</th> 
        </thead>
    </table>  
    <table class="rank" v-else  cellspacing="0" cellpadding="0">
        <thead>
            <th>日期</th>
            <th>排名</th>
            <th>日内盈亏比</th>
            <th>平今笔数</th>
            <th>平今收益</th>

            <!-- <th>净利润</th> -->
            
            <!-- <th>当日期初权益</th>
            <th>当日期末权益</th>
            <th>当日净值</th> -->

            <th>总盈利</th>
            <th>盈利次数</th>
            <th>总亏损</th>
            <th>亏损次数</th>

            <th>当日权益</th>
            <th>当日净值</th> 
        </thead>
        <tbody v-if="ykblist.length>0">
            <tr v-for="(item,index) in ykblist" :key="index" style="border:none;">
                <td>{{item.BCRQ}}</td>
                <td>{{item.RANKID == "0" ? "--" : item.RANKID}}</td>
                <td>{{item.YKB == '999999' ? '999...':item.YKB}}</td>
                <td :class="{red:item.RANKID == '0' }">{{item.jycs}}</td>
                <td>{{item.pcyk}}</td>

                <!-- <td>{{item.DRYL}}</td> -->
                
                <!-- <td>{{item.zrqy}}</td>
                <td>{{item.szqy}}</td>
                <td>{{item.DRJZ}}</td> -->

                <td>{{item.YLJE}}</td>
                <td>{{item.YLCS}}</td>
                <td>{{item.KSJE}}</td>
                <td>{{item.KSCS}}</td>

                <td>{{item.szqy}}</td>
                <td>{{item.DRJZ}}</td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr style="border:none">
                <td v-for="(i,index) in 11" :key="index">--</td>
                <!-- <td colspan="8" class="noData">暂无数据</td> -->
            </tr>
        </tbody>
    </table>
</div>
</template>
<script>
export default {
    props:{
        ykblist: Array,
        showmore:{
            type:Boolean,
            default:false
        }
    }
}
</script>
<style scoped src="./style.css"></style>
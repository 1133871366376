<template>
    <div class="bill">
        <el-dialog class="dialog" title="流水记录"  width="546px" top='30vh' :visible.sync="show" @close="close">
            <div v-loading='loading'>
                <div class="line"></div>
                <div class="billpanel">
                        <el-date-picker
                        v-model="month"
                        type="month"
                        placeholder="选择月">
                        </el-date-picker>
                        <div class="btn" style="margin:0 15px;padding:6px 12px;" @click="checkbill">查询</div>
                </div>
                <div v-if="!detail">
                    <div class="billtitle bw">
                        <div>项目</div>
                        <div>数量</div>
                    </div>
                    <vue-scroll style="height:300px;">
                        <div v-if="fundDetail.length == 0" style="text-align:center;padding:30px 0;">
                            暂无数据
                        </div>
                        <div v-else>
                            <table class="specificTable linetable" cellpadding="0" cellspacing="0">
                                <tr v-for="(item, index) in fundDetail" :key="index " >
                                    <td v-if="index==0 || item.rq != fundDetail[index-1].rq" class="td_top" colspan="2"><div class="tabletitle"><div class="td_left">{{parseInt(item.rq.substring(4, 6)) +'月'+parseInt(item.rq.substring(6, 8)) +'日'}} </div><div  class="td_right"> ({{changetext(item.flag)}} {{item.fsje}}   )</div></div></td>
                                    <td v-if="index!=0 && item.rq == fundDetail[index-1].rq" class="td_left"><span>{{item.flag}}</span><span class="question"  @click="question(item.rq)" v-if="item.id==1"></span></td>
                                    <td v-if="index!=0 && item.rq == fundDetail[index-1].rq" class="td_right">{{item.fsje}}</td>
                                </tr>
                            </table>
                        </div>
                    </vue-scroll>
                </div>
                <div v-else style="text-align:center;">
                    <div class="billtitle bm">
                        <div>品种</div>
                        <div>单笔扣豆</div>
                        <div>手数</div>
                        <div>消费合计</div>
                    </div>
                    <vue-scroll style="height:170px;">
                        <div v-if="dayDetail.length == 0" style="text-align:center;padding:30px 0;">
                            暂无数据
                        </div>
                        <div v-else>
                            <table class="specificTable" cellpadding="0" cellspacing="0" v-if="dayDetail.length>0">
                                <tr v-for="(item, index) in dayDetail" :key="index" class="btr">
                                    <td class="td_left" style="width:25%;text-align: center;"><span> {{item.pzdm}} </span> </td>
                                    <td class="td_right" style="width:25%;border-right: 1px solid #D3D3D3;"><span> {{item.fee}}  </span> </td>
                                    <td class="td_right" style="width:25%;border-right: 1px solid #D3D3D3;"> {{item.cjsl}} </td>
                                    <td class="td_right" style="width:25%"> {{item.sxf}} </td>
                                </tr>
                            </table>
                        </div>
                    </vue-scroll>
                    <div class="btn" style="margin:15px 0 30px;padding:6px 12px;" @click="goback">返回</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
</script>
<script>
import { GetFundDetail,DayDetailJson } from "../../../assets/js/request";
export default {
    name:"bill",
    data(){
        return{
            isread:false,
            month:'',
            show:false,
            fundDetail:[],
            dayDetail:[],
            loading:false,
            detail:false //是否显示账单详情
        }
    },  
   props:{
        listshow:{
            type:Boolean,
            default:false
        },
        whid:String
    },
    watch:{
        listshow(val){
            this.show = val
        }
    },
    created() {
        this.gettime()
  
    },
    methods: {
        // 获取当前年月
        gettime(){
            let date = new Date()
            this.month = date
            // this.month = date.getFullYear()+ '-' + (date.getMonth() + 1)
            this.checkbill()
        },
        async checkbill(){
            if(!this.month) return
            this.loading = true
            let smonth = this.month.getFullYear() + '-' + (this.month.getMonth()+1)
            let data = await GetFundDetail({whid:this.whid,smonth:smonth,page:1,pagesize:500})
            this.loading = false
            this.fundDetail = data.fundDetail
        },
        close(){
            this.$emit("update:listshow",false)
        },
        async question(rq){
            this.dayDetail = []
            this.detail = true
            this.loading = true
            let data = await DayDetailJson({whid:this.whid,rq:rq,page:1,pagesize:500})
            this.loading = false
            this.dayDetail = data.fundDetail;
        },
        goback(){
            this.detail = false

        },
        changetext(str){
            if(str == "当日余额"){
                return "当日剩余"
            }
        }
    

    }
}
</script>
<style scoped src="./style.css"></style>
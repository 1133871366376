<template>
    <div class="tab">
        <div
            v-for="(item,index) in tabList" :key="index"
            class="item"
            :class="{active:activeIndex==item.value}"
            @click="tabClick(item)"
        >{{item.name}}</div>
    </div>
</template>
<script>
export default {
    props:{
        tabList:Array,
        activetabindex:String
    },
    data(){
        return{
            activeIndex:0
        }
    },
    created(){
        this.activeIndex = this.activetabindex
        // this.$emit('tabClick',this.tabList[0]) //初始化
    },
    methods:{
        tabClick(item){
            let vm = this;
            vm.$emit('tabClick',item) //传回被选中的标签实例
        },
        setTabValue(val){
            this.activeIndex = val;
        }
    }
}
</script>
<style scoped>
.tab {
    display: flex;
    width: 260px;
    /* width: 600px; */
    border-radius: 20px;
    background: #EEF2F7;
}
.tab .item {
    font-size: 16px;
    color: #2155A3;
    color: #909090;
    opacity: 0.8;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    flex-grow: 1;
    flex-shrink: 1;
    border-radius: 20px;
}
.tab .item.active,
.tab .item:active {
    position: relative;
    color: #fff;
    background: #71a5f5;
    box-shadow: rgb(56 136 255 / 24%) 2px 3px 4px;
    border-radius: 20px;
}
.tab .item.active::after{
    content: '';
    position: absolute;
    bottom:-12px;
    left: 50%;
    margin-left: -3px;
    width: 0;
    height: 0;
    border:6px solid transparent;
    border-top-color: #71a5f5;


}
</style>

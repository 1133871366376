<template>
    <div class="buyBean">
        <div v-if="!ispay">
            <el-dialog  top='20vh' width="787px" :visible.sync="buyBean" class="buydia" @close="close">
                <div v-loading="loading" v-if="!isshowagreement">
                    <div class="buypanel" style="margin-bottom: 13px;">
                        <div class="buytitle">请选择购买金额：</div>
                        <div class="buyprice">
                            <div class="buytab bean" :class="{active:priceindex == 3}" @click="pricechange(3)">
                                <div class="tabbig">{{price.MonthPrice}}元</div>
                                <div class="tabsmall">{{price.MonthPrice * 100}}个豆</div>
                                <!-- /{{price.MonthPrice * 100}}手 -->
                            </div>
                            <div class="buytab bean" :class="{active:priceindex == 2}" @click="pricechange(2)">
                                <div class="tabbig">{{price.QuarterPrice}}元</div>
                                <div class="tabsmall">{{price.QuarterPrice * 100}}个豆</div>
                            </div>
                            <div class="buytab bean" :class="{active:priceindex == 1}" @click="pricechange(1)">
                                <div class="tabbig">{{price.YearPrice}}元</div>
                                <div class="tabsmall">{{price.YearPrice * 100}}个豆</div>
                            </div>
                        </div>
                    </div>
                    <div class="buypanel">
                            <div class="buytitle" style="padding-top: 10px;">请选择支付方式：</div>
                            <div class="buyprice">
                                <div class="buytab zfb" :class="{active:imgindex == 1}" @click="imgchange(1)">
                                
                                </div>
                                <div class="buytab wx" :class="{active:imgindex == 2}" @click="imgchange(2)">
                                    
                                </div>
                                <div class="buytab yl" :class="{active:imgindex == 3}" @click="imgchange(3)">
                                    
                                </div>
                            </div>
                            <div class="buytips">
                                <div style="font-size:14px;padding: 10px 0 5px;">开具发票说明</div>
                                <div class="buyred">1.需专票的机构请通过汇款的方式付款，服务无法即时开通，详询4008113366。</div>
                                <div>2.发票名头以实名认证的名称为准，不能更改；</div>
                                <div>3.个人名字认证付款后我司开具一张当周所有个人付款汇总电子发票，后附与本人相关销售明细；</div>
                                <div>4.公司名称认证付款后开具电子发票；</div>
                                <div>5.开具汇总发票，当月底前开出；</div>
                                <div>6.获得电子发票：登录<a href="https://u.wenhua.com.cn/" target="_blank" class="link" style="font-size: 13px;text-decoration:none;">文华用户中心</a>，开具电子发票。</div>
                            </div>
                            <div class="buysure" style="margin-bottom: 0;"><input type="checkbox" class="cbox" id="cbox" v-model="isread"><label for="cbox" style="user-select:none;vertical-align: middle;">我已知晓并同意以上关于发票相关事宜</label></div>
                            <div class="buysure" style="margin-top: 0;"><input type="checkbox" class="cbox" id="cbox1" v-model="islink"><label for="cbox1" style="user-select:none;vertical-align: middle;">我已阅读并同意<span class="link" @click="showagreement">赢顺wh6模拟交易服务协议</span></label></div>
                            <div class="buyred" v-if="zjye < 0">购买前补交软件服务欠费{{zjye}}元</div>
                            <div class="buyred">{{message}}</div>
                            <div class="buybtn" @click="pay">确认支付{{finalprice}}元</div>
                    </div>
                </div>
                <div v-else v-loading="loading">
                    <div class="buypanel">
                        <div class="buyagree" v-html="agreehtml"></div>
                        <div class="topright" @click="backbuy">返回</div>
                    </div>
                </div>
            </el-dialog>
        </div>
        <div v-if="ispay">
            <el-dialog  top='26vh' width="400px" :visible.sync="buyBean" class="buydia" @close="close">
                <div style="text-align:center">
                    <div style="margin: 20px 0 10px;">使用{{imgindex == 1 ? '支付宝' : '微信'}}支付<span style="color:rgb(228,78,8);">￥{{finalprice}}</span></div>
                    <img class="payimg" :src="imgsrc">
                </div>
                <div class="imgbottom">
                    <div class="simg"></div>
                    <div>请使用支付宝扫一扫<br>扫描二维码完成支付</div>
                </div>
            </el-dialog>
        </div>

    </div>
</template>
<script>
let stop = false
let num = 0
import { GameCoinApi,GetOrderid,H5PayRequest,H5ChinaPay,MicroPayQRCode,GetIsSuccess,ContractGameCoin} from "../../../assets/js/request";
export default {
    name: 'buyBean',
    data: () => ({
        priceindex:3, //价格默认选中index
        imgindex:1, //图片默认选中index
        isread:false,
        islink:false,
        isshowagreement:false, //是否显示合同
        buyBean:false,
        loading:false,
        price:{}, //价钱汇总
        zjye:0, //资金余额，如果欠费需要补齐
        finalprice:'--',//最终付的价钱
        message:'', //错误提示
        PayGateID:'9001',
        ispay:false, //是否点击支付按钮
        imgsrc:'', //支付二维码地址
        orderid:'', //订单号
        agreehtml:'' //合同内容
    }),
    props:{
        buyshow:{
            type:Boolean,
            default:false
        },
        whid:String
    },
    watch:{
        buyshow(val){
            this.buyBean = val
            this.loading = false
            this.ispay = false
        },
        priceindex(val){
            if(val == 3){
                this.finalprice = this.price.MonthPrice
                if(this.zjye < 0){
                    this.finalprice = (this.price.MonthPrice - parseFloat(this.zjye).toFixed(2))*100/100
                }
            }else if(val == 2){
                this.finalprice = this.price.QuarterPrice
                if(this.zjye < 0){
                    this.finalprice = (this.price.QuarterPrice - parseFloat(this.zjye).toFixed(2))*100/100
                }
            }else if(val == 1){
                this.finalprice = this.price.YearPrice
                if(this.zjye < 0){
                    this.finalprice = (this.price.YearPrice - parseFloat(this.zjye).toFixed(2))*100/100
                }
            }
        },
        imgindex(val){
            if(val == 1){
                this.PayGateID = '9001'
            }else if(val == 2){
                this.PayGateID = '9003'
            }else if(val == 3){
                this.PayGateID = '9000'
            }
        },
        isread(val){
            if(val) this.message = ''
        },
        islink(val){
            if(val) this.message = ''
        }
    },
    created() {
        this.beanprice()
    },
    methods: {
        pricechange(index){
            this.priceindex = index
        },
        imgchange(index){
            this.imgindex = index
        },
        close(){
            this.$emit("update:buyshow",false)
            this.isshowagreement = false
            this.isread = false
            this.islink = false
            stop = true
        },
        // 获取文华豆价钱
        async beanprice(){
            this.loading = true
            let data = await GameCoinApi({whid:this.whid});
            this.loading = false
            this.price = data.price
            this.zjye = data.zjye
            this.finalprice = this.price.MonthPrice
            if(this.zjye < 0){
                this.finalprice = this.price.MonthPrice - parseFloat(this.zjye).toFixed(2)
            }
        },
        async pay(){
            if(!this.isread){
                this.message = '请勾选“我已知晓并同意以上关于发票相关事宜”'
                return false
            }
            if(!this.islink){
                this.message = '请勾选“我已阅读并同意赢顺wh6模拟交易服务协议”'
                return false
            }
            this.loading = true
            this.orderid = await GetOrderid({PayGateID:this.PayGateID})
            console.log(stop)
            if(stop){
                stop = false
                return false
            }

            let param = {whid:this.whid,PayGateID:this.PayGateID,out_trade_no:this.orderid,sCardType:this.priceindex,issaleoff:false}
            if(this.imgindex != 3){
                // 支付宝微信
                console.log({param})
                let data = await H5PayRequest(param)
                this.loading = false
                if(data.code == "wxqrcode" || data.code == "aliqrcode" || data.code == "chinapay_qrcode"){
                    let img = await MicroPayQRCode({data:data.message})
                    this.ispay = true
                    this.imgsrc ='data:image/png;base64,' + img
                    this.getsuccess()
                }
                
            }else{
                // 银联
                this.loading = false
                window.open('http://172.16.8.203:842/Charge/H5ChinaPay?whid='+this.whid+'&PayGateID='+this.PayGateID+'&out_trade_no='+this.orderid+'&sCardType='+this.priceindex+'&issaleoff=false')
                // let data = await H5ChinaPay(param)
                
            }
        },
        // 是否支付成功
        async getsuccess(){
            if(stop){
                stop = false
                return false
            }
            if(!this.ispay) return
            let data = await GetIsSuccess({out_trade_no:this.orderid})
            if (data) {
                num++
            }
            if(num <= 60){
                this.getsuccess()
            }else{
                num = 0
                this.$router.push({
                    path: "/result",
                    query: {
                        out_trade_no: this.orderid
                    }
                });
            }

        },
        async showagreement(){
            this.isshowagreement = true
            // this.islink = true
            this.loading = true
            let data = await ContractGameCoin({whid:this.whid,sCardType:this.priceindex})
            this.loading = false
            if(data.status){
                this.agreehtml = data.data
            }else{
                this.agreehtml = data.message
            }
            

        },
        backbuy(){
            this.isshowagreement = false
        }

    }
}
</script>
<style scoped src="./style.css"></style>
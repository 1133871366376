<template>
    <div class="quit">
        <el-dialog class="dialog" title="退赛"  width="546px" top='30vh' :visible.sync="quit" @close="close">
            <div class="line"></div>
            <div class="quitpanel" v-loading='loading'>
                <div style="text-align:center;" v-if="name">姓名：{{name}}</div>
                <div style="padding:15px 0 30px" v-if="phone">手机号/模拟交易账号：{{phone}}</div>
                <div class="checkquit" :class="{'bounce':bounce}">
                    <span><input type="checkbox" class="cbox" id="cbox1" v-model="isread"></span>
                    <label for="cbox1" style="user-select:none;vertical-align: middle;">我已知晓&nbsp;当月退赛后不能再次参赛，退赛前成绩作废不参与最终排名</label>
                    <!-- <label for="cbox1" style="user-select:none;vertical-align: middle;">我已知晓&nbsp;凡中途退赛的用户，不可重新参赛，退赛前成绩作废不参与最终排名。</label> -->
                </div>
                <div v-if="message" class="buyred" style="padding: 0 17px;margin: 3px 0 -4px;font-size: 12px;">{{message}}</div>
                <div class="quitbtn">
                    <div class="btn" style="margin-top:0;" @click="quitback">确认退赛</div>
                    <div class="btn" style="margin-top:0;margin-right:50px" @click="close">取消</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
</script>
<script>
import {GetWallet,Quit} from "../../../assets/js/request";
let time = null
export default {
    name:"quit",
    data: () => ({
        isread:false,
        quit:false,
        message:'',
        loading:false,
        name:'',
        phone:'',
        bounce:false // 是否抖动
    }),
   props:{
        quitshow:{
            type:Boolean,
            default:false
        },
        whid:String
    },
    watch:{
        quitshow(val){
            this.quit = val
        },
        isread(val){
            if(val){
                this.message  = ''
            }
        }
    },
    created() {
        this.info()
    },
    methods: {
        close(){
            this.$emit("update:quitshow",false)
        },
        async quitback(){
            // if(!this.isread){
            //     this.message = '请勾选“我已知晓 凡大赛中途退赛的用户，不可重新参赛，退赛前成绩作废不参与最终排名。”'
            //     return false
            // }
            this.bounce = false
            if(!this.isread){
                this.bounce = true
                time && clearTimeout(time);
                time = setTimeout(()=>{
                    this.bounce = false
                },400)
                return false
            }
            let phone = localStorage.getItem('phone')
            let data = await Quit({whid:this.whid,phonenum: phone})
            if(data.status){
                this.$message({
                    message: data.message,
                    type: 'success',
                    onClose:()=>{
                        this.close()
                        location.reload()
                    }
                });
            }else{
                this.message = data.message
            }
            
        },
        iphoneEncryption (iphone) {
            var item = iphone ; 
            if(item.length == 11){
                return item.substring(0,3) + '*****' + item.substring(8)
            }else{
                return item.substring(0,3) + '****' + item.substring(7)
            }
        },
     
        async info(){
            this.loading = true
            let data = await GetWallet({whid:this.whid})
            this.loading = false
            if(data.status){
                this.name = data.data.realname
                // this.phone = this.iphoneEncryption(data.data.phone) 
                this.phone = data.data.phone
            }
        }
    }
}
</script>
<style scoped src="./style.css"></style>